import "./details.scss";

import { Component } from "react";
import classnames from "classnames";
import { useNavigate, useParams } from "react-router-dom";

import { useDispatch } from "redux/util";
import Modal from "common/modal";
import Button from "common/core/button";
import { DeprecatedDetailGrid } from "common/details/grid";
import { DeprecatedDetailGridSection } from "common/details/grid/section";
import { DeprecatedDetailGridRow } from "common/details/grid/row";
import { MailtoLink } from "common/core/mailto_link";
import LoadingIndicator from "common/core/loading_indicator";
import Fullname from "common/user/user_full_name";
import { newPathWithPreservedSearchParams } from "util/location";
import { userFullName } from "util/user";
import { useQuery, useMutation } from "util/graphql";
import { forgotPassword } from "redux/actions/authentication";
import MultiSelectInput from "common/form/inputs/multi_select";
import { usePermissions } from "common/core/current_user_role";

import AdminResponseModal from "./admin_response_modal";
import AdminConfirmModal from "./admin_confirm_modal";
import AdminListDashboardAdminDetailsQuery, {
  type AdminListDashboardAdminDetails_admin_User as User,
} from "./details_query.graphql";
import DeleteAdminMutation, {
  type DeleteAdmin,
  type DeleteAdminVariables,
} from "./delete_admin_mutation.graphql";
import UpdateAdminMutation, {
  type UpdateAdmin,
  type UpdateAdminVariables,
} from "./update_admin_mutation.graphql";

type Confirmation = "closed" | "reset" | "delete";
type Props = {
  navigate: ReturnType<typeof useNavigate>;
  dispatch: ReturnType<typeof useDispatch>;
  permissions: ReturnType<typeof usePermissions>;
  admin: User;
  deleteAdminMutateFn: ReturnType<typeof useMutation<DeleteAdmin, DeleteAdminVariables>>;
  updateAdminMutateFn: ReturnType<typeof useMutation<UpdateAdmin, UpdateAdminVariables>>;
};

const INITIAL_STATE = {
  confirmationModalState: "closed" as Confirmation,
  responseModalOpen: false,
  responseModalMsg: null as null | string,
};

class AdminDetailsModal extends Component<Props, typeof INITIAL_STATE> {
  state = INITIAL_STATE;

  onChange = (roles: { value: string }[]) => {
    const newValues = roles.map((role) => ({ userRoleId: role.value }));
    if (!newValues.length) {
      return;
    }
    const { admin, updateAdminMutateFn } = this.props;
    const removeExisting = admin.adminProfile!.roleSchedules.map((role) => ({
      id: role.id,
      delete: true,
    }));
    const roleSchedules = [...removeExisting, ...newValues];
    return updateAdminMutateFn({
      variables: { input: { roleSchedules, id: admin.id } },
    });
  };

  closeConfirmation = () => {
    this.setState({ confirmationModalState: "closed" });
  };

  resetPassword = () => {
    const { admin } = this.props;
    this.props.dispatch(forgotPassword(admin.email!));
    this.closeConfirmation();
  };

  deleteAdmin = () => {
    const { admin, deleteAdminMutateFn } = this.props;
    return deleteAdminMutateFn({
      variables: { input: { id: admin.id } },
      refetchQueries: ["AdminListDashboard"],
    })
      .then(() => {
        this.props.navigate(newPathWithPreservedSearchParams("/admin/records"));
      })
      .catch((error: Error) => {
        this.setState({ responseModalOpen: true, responseModalMsg: error.message });
        this.closeConfirmation();
        return Promise.reject(error);
      });
  };

  render() {
    const {
      admin,
      permissions: { hasPermissionFor },
    } = this.props;
    const { confirmationModalState, responseModalOpen, responseModalMsg } = this.state;
    const canEdit = hasPermissionFor("editAdmin");
    const name = admin.firstName ? <Fullname user={admin} /> : null;

    const { enabled, assignableRoles } = admin.adminProfile!;
    const statusCx = classnames({
      "status-enabled": enabled,
      "status-disabled": !enabled,
      "status-pending": enabled === null,
    });
    const adminStatus = enabled ? "Enabled" : enabled === null ? "Pending" : "Disabled";
    const roleOptions = assignableRoles.map((role) => ({
      label: role.displayName,
      value: role.id,
    }));

    return (
      <Modal
        title="Admin Details"
        className="AdminDetails"
        closeRoute={newPathWithPreservedSearchParams("/admin/records")}
      >
        <DeprecatedDetailGrid>
          <DeprecatedDetailGridSection>
            <DeprecatedDetailGridRow title="Name">
              <strong>{userFullName(admin, "[No name given]")}</strong>
            </DeprecatedDetailGridRow>
          </DeprecatedDetailGridSection>

          <DeprecatedDetailGridSection>
            <DeprecatedDetailGridRow title="Email Address">
              <MailtoLink emailAddress={admin.email!} />
            </DeprecatedDetailGridRow>
          </DeprecatedDetailGridSection>

          <DeprecatedDetailGridSection>
            <DeprecatedDetailGridRow title="Status">
              <span className={statusCx}>{adminStatus}</span>
            </DeprecatedDetailGridRow>
          </DeprecatedDetailGridSection>

          <DeprecatedDetailGridSection>
            <DeprecatedDetailGridRow title="Roles">
              <MultiSelectInput
                items={roleOptions}
                onChange={this.onChange}
                value={admin.adminProfile!.roleSchedules.map((role) => role.userRoleId)}
                disabled={!canEdit}
              />
            </DeprecatedDetailGridRow>
          </DeprecatedDetailGridSection>
          {canEdit && (
            <DeprecatedDetailGridSection>
              <DeprecatedDetailGridRow title="Actions" className="Actions">
                <div>
                  <Button
                    buttonColor="action"
                    variant="primary"
                    automationId="reset-admin-password"
                    onClick={() => {
                      this.setState({ confirmationModalState: "reset" });
                    }}
                  >
                    Reset Password
                  </Button>
                  <Button
                    buttonColor="danger"
                    variant="primary"
                    automationId="delete-admin"
                    onClick={() => {
                      this.setState({ confirmationModalState: "delete" });
                    }}
                  >
                    Delete
                  </Button>
                </div>
              </DeprecatedDetailGridRow>
            </DeprecatedDetailGridSection>
          )}
        </DeprecatedDetailGrid>

        {confirmationModalState === "reset" ? (
          <AdminConfirmModal
            subheader={<span>An email will be sent to {name || "the admin"}.</span>}
            confirmText="Yes, reset"
            confirm={this.resetPassword}
            cancel={this.closeConfirmation}
          />
        ) : confirmationModalState === "delete" ? (
          <AdminConfirmModal
            subheader={<span>{name || "The admin"} will have their account deleted.</span>}
            confirmText="Yes, delete"
            confirm={this.deleteAdmin}
            cancel={this.closeConfirmation}
          />
        ) : null}

        <AdminResponseModal
          isOpen={responseModalOpen}
          onClose={() => this.setState({ responseModalOpen: false })}
          responseModalMsg={responseModalMsg}
        />
      </Modal>
    );
  }
}

function AdminDetailsModalContainer() {
  const { globalID } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const permissions = usePermissions();
  const deleteAdminMutateFn = useMutation(DeleteAdminMutation);
  const updateAdminMutateFn = useMutation(UpdateAdminMutation);

  const { data, loading } = useQuery(AdminListDashboardAdminDetailsQuery, {
    variables: { adminUserId: globalID! },
  });
  if (loading) {
    return <LoadingIndicator />;
  }

  const { admin } = data!;
  if (admin?.__typename !== "User") {
    throw new Error(`Expected User, got ${admin?.__typename}`);
  }
  return (
    <AdminDetailsModal
      admin={admin}
      dispatch={dispatch}
      navigate={navigate}
      permissions={permissions}
      deleteAdminMutateFn={deleteAdminMutateFn}
      updateAdminMutateFn={updateAdminMutateFn}
    />
  );
}

export default AdminDetailsModalContainer;
