import { useMemo, useCallback, type ComponentProps } from "react";
import { useParams, useNavigate, useSearchParams, Outlet } from "react-router-dom";
import { defineMessages, useIntl } from "react-intl";

import {
  DateCreatedColumn,
  SenderColumn,
  TransactionIdColumn,
  StatusColumn,
  ClientColumn,
  FraudIssueColumn,
  RiskLevelColumn,
} from "common/dashboard/columns";
import {
  StatusFilter,
  TransactionTypeFilter,
  ClosingDateFilter,
  useDateConstraint,
  useToggleSetWithCallback,
} from "common/dashboard/filter_dropdown/common";
import { SettingsHeader, SettingsPageWrapper } from "common/settingsv2/common";
import Table from "common/core/table";
import TablePagination, { usePagination } from "common/core/table/pagination";
import TableSearch from "common/core/table/search";
import { useQuery } from "util/graphql";
import { usePrependedDocumentTitle } from "util/html";
import { newPathWithPreservedSearchParams } from "util/location";
import { useDebouncedQuery, useFilter } from "common/dashboard/filter";
import { fraudDashboardCallout } from "common/dashboard/callouts";
import {
  OrganizationTransactionStatus,
  OrganizationTransactionDetailedStatus,
  type MortgageTransactionType,
  OrganizationTypeEnum,
} from "graphql_globals";

import { adminDashboardTransactionDeserializer, adminDashboardSerializer } from "./filter";
import AdminCompanyDetailsTransactionsGraph, {
  type AdminCompanyDetailsTransactions_node_Organization_transactions_edges_node as OrganizationTransaction,
} from "./index.query.graphql";
import Styles from "./index.module.scss";

const PAGE_SIZE = 50;

const MESSAGES = defineMessages({
  searchPlacholder: {
    id: "3705852e-6a32-4da4-a0ab-1fd5f317f85f",
    defaultMessage: "Search by name, email, or transaction ID",
  },
  searchLabel: {
    id: "a7d7f3b9-1c3d-4e3b-8c3b-0b1c4b5f9d7e",
    defaultMessage: "Search for a transaction",
  },
  pageTitle: {
    id: "fdc979aa-035e-4dcd-9b68-5a31b1d22fec",
    defaultMessage: "Transactions",
  },
});

const getFilterVariables = ({
  organizationId,
  pageIndex,
  selectedTransactionTypes,
  startDate,
  endDate,
  query,
  selectedStatuses,
}: {
  organizationId: string;
  pageIndex: number;
  selectedTransactionTypes: Set<MortgageTransactionType>;
  startDate: string | null;
  endDate: string | null;
  query: string | null;
  selectedStatuses: Set<OrganizationTransactionDetailedStatus>;
}) => {
  return {
    offset: PAGE_SIZE * pageIndex,
    organizationId,
    transactionTypes: Array.from(selectedTransactionTypes.values()),
    closingDateStart: startDate,
    closingDateEnd: endDate,
    query,
    detailedStatuses: Array.from(selectedStatuses.values()),
  };
};

export default function AdminCompanyDetailsTransactionsContent() {
  const { globalID } = useParams();
  const intl = useIntl();
  const navigate = useNavigate();
  const [rawQueryArgs] = useSearchParams();
  usePrependedDocumentTitle(intl.formatMessage(MESSAGES.pageTitle));

  const { handleChange, deserializedArgs } = useFilter(
    adminDashboardTransactionDeserializer,
    adminDashboardSerializer,
  );

  const {
    page: pageIndex,
    query,
    detailedStatuses: selectedStatuses,
    transactionTypes: selectedTransactionTypes,
    dateConstraint: selectedDateConstraint,
  } = deserializedArgs;

  const { textFilterValue, handleTextFilterChange } = useDebouncedQuery(handleChange, query);

  const setPageIndex = useCallback(
    (page: number) => {
      handleChange({ page });
    },
    [handleChange],
  );

  const getTransactionLinkProps = useCallback(
    (transaction: OrganizationTransaction) => {
      return { to: newPathWithPreservedSearchParams(`${transaction.id}/summary`) };
    },
    [rawQueryArgs],
  );

  const handleRowClick = useCallback(
    (transaction: OrganizationTransaction) => {
      const { to } = getTransactionLinkProps(transaction);
      navigate(to);
    },
    [navigate, getTransactionLinkProps],
  );

  const {
    clearSelection: clearStatusesSelection,
    removeAll: removeAllStatuses,
    addAll: addAllStatuses,
    toggleSelection: toggleStatusSelection,
  } = useToggleSetWithCallback(selectedStatuses, handleChange, "detailedStatuses");

  const {
    clearSelection: clearTransactionTypesSelection,
    toggleSelection: toggleTransactionTypeSelection,
    removeAll: removeAllTransactionTypes,
    addAll: addAllTransactionTypes,
  } = useToggleSetWithCallback(selectedTransactionTypes, handleChange, "transactionTypes");

  const setSelectedDateConstraint = useCallback<
    ComponentProps<typeof ClosingDateFilter>["setSelectedConstraint"]
  >(
    (dateConstraint) => {
      handleChange({ dateConstraint: dateConstraint || undefined, page: 0 });
    },
    [handleChange],
  );

  const { startDate, endDate } = useDateConstraint(
    selectedDateConstraint,
    setSelectedDateConstraint,
  );

  const inProgressStatuses = useMemo(
    () => [
      OrganizationTransactionDetailedStatus.SENT_TO_SIGNER,
      OrganizationTransactionDetailedStatus.ACTIVE,
      OrganizationTransactionDetailedStatus.VIEWED,
      OrganizationTransactionDetailedStatus.PARTIALLY_COMPLETE,
      OrganizationTransactionDetailedStatus.ATTEMPTED,
      OrganizationTransactionDetailedStatus.ORDER_PLACED,
      OrganizationTransactionDetailedStatus.SENT_TO_TITLE_AGENT,
      OrganizationTransactionDetailedStatus.AWAITING_PAYMENT,
      OrganizationTransactionDetailedStatus.ON_HOLD,
    ],
    [],
  );
  const completeStatuses = useMemo(
    () => [
      OrganizationTransactionDetailedStatus.COMPLETE,
      OrganizationTransactionDetailedStatus.ESIGN_COMPLETE,
      OrganizationTransactionDetailedStatus.WET_SIGN_COMPLETE,
      OrganizationTransactionDetailedStatus.COMPLETE_WITH_REJECTIONS,
    ],
    [],
  );
  const otherOptions = useMemo(
    () =>
      [
        [
          OrganizationTransactionStatus.ACTION_NEEDED,
          OrganizationTransactionDetailedStatus.OPEN_ORDER,
        ],
        [
          OrganizationTransactionStatus.LIVE,
          OrganizationTransactionDetailedStatus.MEETING_IN_PROGRESS,
        ],
        [OrganizationTransactionStatus.FAILURE, OrganizationTransactionDetailedStatus.EXPIRED],
        [OrganizationTransactionStatus.INACTIVE, OrganizationTransactionDetailedStatus.DRAFT],
        [OrganizationTransactionStatus.INACTIVE, OrganizationTransactionDetailedStatus.RECALLED],
      ] as [OrganizationTransactionStatus, OrganizationTransactionDetailedStatus][],
    [],
  );

  const variables = getFilterVariables({
    pageIndex,
    organizationId: globalID!,
    selectedTransactionTypes,
    startDate,
    endDate,
    query,
    selectedStatuses,
  });

  const { data, loading } = useQuery(AdminCompanyDetailsTransactionsGraph, { variables });

  const organization = data ? data.node! : null;
  if (organization && organization.__typename !== "Organization") {
    throw new Error(`Expected organization, got ${organization.__typename}.`);
  }

  const transactions = organization ? organization.transactions : null;
  const totalCount = transactions?.totalCount ?? 0;
  const pageCount = Math.max(Math.ceil(totalCount / PAGE_SIZE), 1);

  const columns = useMemo(() => {
    return [
      FraudIssueColumn,
      ClientColumn(intl),
      SenderColumn,
      DateCreatedColumn(),
      TransactionIdColumn,
      RiskLevelColumn,
      StatusColumn,
    ];
  }, [deserializedArgs]);

  const items = useMemo(
    () => (transactions ? transactions.edges.map(({ node }) => node) : []),
    [transactions],
  );

  const { canNextPage, canPreviousPage, nextPage, previousPage, startIndex, endIndex } =
    usePagination({
      disabled: loading,
      pageIndex,
      pageCount,
      pageSize: PAGE_SIZE,
      items,
      onPageChange: setPageIndex,
    });

  return (
    <>
      <SettingsPageWrapper>
        <SettingsHeader title={intl.formatMessage(MESSAGES.pageTitle)} />
        <div className={Styles.tableControlContainer}>
          <div className={Styles.filters}>
            <TableSearch
              value={textFilterValue}
              placeholder={intl.formatMessage(MESSAGES.searchPlacholder)}
              aria-label={intl.formatMessage(MESSAGES.searchLabel)}
              onChange={handleTextFilterChange}
            />
            <StatusFilter
              selectedStatuses={selectedStatuses}
              inProgressStatuses={inProgressStatuses}
              completeStatuses={completeStatuses}
              otherOptions={otherOptions}
              clearSelection={clearStatusesSelection}
              removeAll={removeAllStatuses}
              addAll={addAllStatuses}
              toggleSelection={toggleStatusSelection}
              disabled={loading}
            />
            {organization?.organizationType !== OrganizationTypeEnum.BUSINESS && (
              <>
                <TransactionTypeFilter
                  selectedTypes={selectedTransactionTypes}
                  clearSelection={clearTransactionTypesSelection}
                  toggleSelection={toggleTransactionTypeSelection}
                  addAll={addAllTransactionTypes}
                  removeAll={removeAllTransactionTypes}
                  disabled={loading}
                />
                <ClosingDateFilter
                  disabled={loading}
                  selectedConstraint={selectedDateConstraint}
                  setSelectedConstraint={setSelectedDateConstraint}
                />
              </>
            )}
          </div>
          <div>
            <TablePagination
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              nextPage={nextPage}
              previousPage={previousPage}
              startIndex={startIndex}
              endIndex={endIndex}
              totalCount={totalCount}
            />
          </div>
        </div>
        <Table
          caption={intl.formatMessage(MESSAGES.pageTitle)}
          data={items}
          columns={columns}
          loading={loading}
          rowInteraction={{
            onClick: handleRowClick,
            getLinkProps: getTransactionLinkProps,
            getCallout: fraudDashboardCallout,
          }}
          totalItems={totalCount}
        />
      </SettingsPageWrapper>
      <Outlet />
    </>
  );
}
