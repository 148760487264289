import { memo, useCallback, useEffect, useState } from "react";
import { reduxForm, type InjectedFormProps, SubmissionError, clearSubmitErrors } from "redux-form";
import { useDispatch } from "react-redux";

import { composeValidators } from "util/form";
import { validatePresence } from "validators/form";
import FormGroup from "common/form/group";
import SaveButton from "common/core/save_button";
import TextField from "common/form/fields/text";
import { useMutation } from "util/graphql";
import type { FormError } from "errors/util";
import { customMessage } from "errors/form";
import FormGroupErrors from "common/form/group_errors";
import { isGraphQLError } from "util/graphql/query";

import UpdateEOCredentialsMutation from "./update_eo_credentials_mutation.graphql";
import type { EOriginalCredentials } from "./eoriginal_fragment.graphql";
import Styles from "./eoriginal.module.scss";

type Props = {
  organizationId: string;
  eOriginalCredentials: EOriginalCredentials | null;
};

type InnerProps = InjectedFormProps<FormValues, Props, FormError> & Props;

type FormValues = EOriginalCredentials & { apiKey: string; submissionError: string };

function EOriginalCredentialsForm({
  eOriginalCredentials,
  organizationId,
  handleSubmit,
  initialize,
}: InnerProps) {
  const { username, organizationName } = eOriginalCredentials || {};
  useEffect(() => {
    initialize({ username, organizationName });
  }, []);

  const [submitting, setSubmitting] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const dispatch = useDispatch();
  const handleFieldChange = useCallback(() => {
    setCanSave(true);
    dispatch(clearSubmitErrors("eOriginalCredentials"));
  }, [dispatch]);

  const updateEOCredentialsMutateFn = useMutation(UpdateEOCredentialsMutation);
  const onSubmit = useCallback(
    (data: FormValues) => {
      const { username, organizationName, apiKey } = data;
      setSubmitting(true);
      return updateEOCredentialsMutateFn({
        variables: {
          mutationInput: {
            organizationId,
            eoApiKey: apiKey,
            eoLogin: username,
            eoOrgName: organizationName,
          },
        },
      })
        .then(() => setCanSave(false))
        .catch((error: Error) => {
          const message =
            (isGraphQLError(error) && error.graphQLErrors[0]?.specifics) ||
            "Failed to update eOriginal credentials";
          return Promise.reject(
            new SubmissionError<FormValues, FormError>({
              submissionError: customMessage({ message }),
            }),
          );
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [organizationId, eOriginalCredentials],
  );

  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <FormGroup disableFormRowStyle fields={["username"]}>
        <TextField
          className={Styles.input}
          id="username"
          name="username"
          placeholder="eOriginal Login"
          autoComplete="off"
          onChange={handleFieldChange}
          data-automation-id="eOriginal-login"
        />
        <FormGroupErrors fields={["username"]} />
      </FormGroup>

      <FormGroup disableFormRowStyle fields={["organizationName"]}>
        <TextField
          className={Styles.input}
          id="organizationName"
          name="organizationName"
          placeholder="eOriginal Organization Name"
          autoComplete="off"
          onChange={handleFieldChange}
          data-automation-id="eOriginalOrgName"
        />
        <FormGroupErrors fields={["organizationName"]} />
      </FormGroup>

      <FormGroup disableFormRowStyle fields={["apiKey"]}>
        <TextField
          className={Styles.input}
          id="apiKey"
          name="apiKey"
          type="password"
          placeholder="Api Key"
          autoComplete="off"
          onChange={handleFieldChange}
          data-automation-id="eOriginal-apikey"
        />
        <FormGroupErrors fields={["apiKey"]} />
      </FormGroup>
      <FormGroupErrors fields={["submissionError"]} />
      {canSave && (
        <SaveButton
          disabled={submitting}
          isLoading={submitting}
          className="is-form"
          title="Save Credentials"
        />
      )}
    </form>
  );
}

const validate = (values: FormValues) =>
  composeValidators(
    validatePresence({ field: "organizationName", label: "eOriginal Organization Name" }),
    validatePresence({ field: "username", label: "eOriginal Login" }),
    validatePresence({ field: "apiKey", label: "Api Key" }),
  )(values);

const formEnhancer = reduxForm<FormValues, Props, FormError>({
  form: "eOriginalCredentials",
  validate,
});

export default memo(formEnhancer(EOriginalCredentialsForm));
