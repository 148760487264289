import { FormattedMessage } from "react-intl";
import { type ReactNode, useEffect, useState } from "react";

import { Paragraph } from "common/core/typography";
import { useAppDispatch } from "redux/hooks";
import { forgotPassword } from "redux/actions/authentication";
import { bundlePrepareRoute } from "util/routes";
import Button from "common/core/button";

import ProofCard from "../card";
import Styles from "./index.module.scss";

export type VerifyEmailScreenType = {
  type: "verify_email";
  email: string;
  bundleId: string | undefined;
};

const SEND_STATE = {
  SENDING: "SENDING",
  SENT: "SENT",
  FAILED: "FAILED",
  INITIAL: "INITIAL",
};

type Props = {
  onBack: () => void;
  showCard?: boolean;
  email: string;
  bundleId: string | undefined;
  callback?: () => void;
  wrapper?: (children: ReactNode) => ReactNode;
};

function VerifyEmailContent({ email, bundleId, callback, onBack, wrapper }: Props) {
  const dispatch = useAppDispatch();
  const [sendState, setSendState] = useState(SEND_STATE.INITIAL);
  const handleSendVerifyEmail = async () => {
    const redirect = bundleId ? bundlePrepareRoute(bundleId) : undefined;
    await dispatch(forgotPassword(email, redirect));
    callback?.();
  };
  const sendEmail = async () => {
    setSendState(SEND_STATE.SENDING);

    try {
      await handleSendVerifyEmail();
      setSendState(SEND_STATE.SENT);
    } catch {
      setSendState(SEND_STATE.FAILED);
    }
  };

  useEffect(() => {
    sendEmail();
  }, []);

  return (
    <div>
      <Paragraph size="large">
        <FormattedMessage
          id="81f55007-e5b1-46e2-9802-fbb98a299fbd"
          defaultMessage="Looks like you've been here before — please check your email to set a password then come back to this page."
        />
      </Paragraph>
      <Button
        className={Styles.spacingTop}
        onClick={sendEmail}
        isLoading={sendState === SEND_STATE.SENDING}
        fullwidth
        buttonColor="action"
        variant="primary"
      >
        <FormattedMessage
          id="a2a7a492-c50c-44ff-9de6-dd0c07ecf6ae"
          description="resend"
          defaultMessage="Resend Email"
        />
      </Button>

      {!wrapper && (
        <Button
          className={Styles.spacingTop}
          onClick={onBack}
          fullwidth
          variant="secondary"
          buttonColor="action"
        >
          <FormattedMessage
            id="5013b604-ee27-46ab-b55e-b9b9ac2032c4"
            defaultMessage="Back to sign in"
          />
        </Button>
      )}
      {sendState === SEND_STATE.FAILED && (
        <Paragraph className={Styles.verifyError}>
          <FormattedMessage
            id="19bb2541-667b-4ec8-b8a1-cb70c6b52948"
            defaultMessage="There was an error sending the email. Please try again or contact support."
          />
        </Paragraph>
      )}
    </div>
  );
}

export default function VerifyEmailScreen({
  onBack,
  showCard,
  email,
  bundleId,
  callback,
  wrapper,
}: Props) {
  const content = <VerifyEmailContent {...{ email, bundleId, callback, onBack, wrapper }} />;

  if (showCard) {
    return <ProofCard body={content} />;
  }

  return <>{wrapper ? wrapper(content) : content} </>;
}
