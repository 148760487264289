import { useEffect } from "react";

import { useQuery } from "util/graphql";

import ProofReasonCodesQuery from "./proof_reason_codes.query.graphql";
import { useProofReasonCodes } from "./context";

export default function ReasonCodeLoader() {
  const { data, loading } = useQuery(ProofReasonCodesQuery);
  const { setOriginalCodes } = useProofReasonCodes();
  // This would be better as a useMemo, but this is implemented as react state
  // and one component can't update another component's state during a render.
  useEffect(() => {
    setOriginalCodes(data?.viewer.proofReasonCodes, loading);
  }, [data, loading]);
  return null;
}
