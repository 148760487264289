import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import { useState, useEffect, type ReactNode } from "react";

import { Checkbox, CheckboxLabel } from "common/core/form/option";
import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";
import { useForm, type UseFormReturn } from "common/core/form";
import { AutomaticFormRow, Label } from "common/core/form/layout";
import { NumberInput, TextInput, TextAreaInput } from "common/core/form/text";
import { defaultRequiredMessage, isAriaInvalid } from "common/core/form/error";
import { useId } from "util/html";
import { isGraphQLError } from "util/graphql/query";

import Styles from "./index.module.scss";
import type { AdminCompanyPanelsDash_organization_Organization as Organization } from "../index_query.graphql";
import type { AdminPanelInfo_panel_Panel as Panel } from "../panel_info/index_query.graphql";

type BaseProps = {
  onSave: (formValues: FormValues) => Promise<unknown>;
  onCancel: () => void;
};

type Props = BaseProps & {
  children: ReactNode;
  buttonLabel: ReactNode;
  title: ReactNode;
  defaultValues?: FormValues;
};

type EditProps = BaseProps & {
  panel: Panel;
};

type AddProps = BaseProps & {
  organization: Organization;
};

type SelectProps = {
  form: UseFormReturn<FormValues>;
};

type FormValues = {
  panelName: string;
  routingExpiry?: number | null;
  description?: string | null;
  allowsAssignment: boolean;
};

const MESSAGES = defineMessages({
  panelName: {
    id: "7afb622b-ce6f-48da-a3b0-48f17d30cbdf",
    defaultMessage: "Panel name",
  },
  overFlowError: {
    id: "1fdade27-cd72-467c-a21e-9f5cc5541631",
    defaultMessage: "Please input a whole number",
  },
  overFlowPlaceHolder: {
    id: "a5d4449d-20b7-42fa-b2bb-a91a24e50ec4",
    defaultMessage: "ex: 15",
  },
});

function AddOrEditOrgPanelModal({
  onSave,
  onCancel,
  children,
  title,
  buttonLabel,
  defaultValues,
}: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [overflowActive, setOverflowActive] = useState(Boolean(defaultValues?.routingExpiry));
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const form = useForm<FormValues>({ defaultValues });
  const { formState, register } = form;
  const { errors } = formState;
  const intl = useIntl();
  const panelDescriptionId = useId();
  const panelDescriptionHelpId = useId();

  function renderError(): ReactNode | null {
    if (!errorMessage) {
      return null;
    }

    return (
      <span className={Styles.inputError}>
        {errorMessage === "invalid_routing_expiry" ? (
          <FormattedMessage
            id="b102474a-a9d7-481d-907c-8f52711a1ea9"
            defaultMessage="Minimum overflow setting is 15 seconds"
          />
        ) : (
          <FormattedMessage
            id="e4a4c85d-186d-47e3-ab54-ea8591da4f5b"
            defaultMessage="Request failed, please try again"
          />
        )}
      </span>
    );
  }

  return (
    <WorkflowModal
      large
      buttons={[
        <Button
          key="cancel"
          variant="tertiary"
          buttonColor="dark"
          onClick={onCancel}
          disabled={isLoading}
        >
          <FormattedMessage id="8ce6a854-52d9-49a8-bb01-5962150654b1" defaultMessage="Cancel" />
        </Button>,
        <Button
          isLoading={isLoading}
          key="createorupdate"
          variant="primary"
          buttonColor="action"
          onClick={form.handleSubmit((formValues: FormValues) => {
            setIsLoading(true);
            onSave(formValues).catch((error) => {
              if (isGraphQLError(error) && error.graphQLErrors.length) {
                setErrorMessage(error.graphQLErrors[0]?.message);
              }
              setIsLoading(false);
            });
          })}
        >
          {buttonLabel}
        </Button>,
      ]}
      title={title}
      closeBehavior={{
        tag: "with-button",
        onClose: onCancel,
      }}
      footerSeparator={false}
    >
      <div className={Styles.modalContent}>
        {children}
        <div>
          <AutomaticFormRow<FormValues>
            fullWidth
            form={form}
            name="panelName"
            registerOptions={{ required: defaultRequiredMessage(intl) }}
            as={TextInput}
            aria-label={intl.formatMessage(MESSAGES.panelName)}
            label={
              <FormattedMessage
                id="f4159e21-031f-40c4-af81-6fb947fdb800"
                defaultMessage="Panel name"
              />
            }
            placeholder="ex: Virtual Mailbox"
          />
        </div>
        <div>
          <Label htmlFor={panelDescriptionId} className={Styles.fieldHeading}>
            <FormattedMessage
              id="ee043c03-6d91-401e-9943-0ce2375091ff"
              defaultMessage="Company description"
            />
          </Label>
          <div id={panelDescriptionHelpId} className={Styles.fieldLabel}>
            <FormattedMessage
              id="8898a5ce-ed6d-42a4-bbb7-43d189a7c22a"
              defaultMessage="Describe the company panel for notaries to view"
            />
          </div>
          <TextAreaInput
            id={panelDescriptionId}
            aria-invalid={isAriaInvalid(errors.description)}
            aria-describedby={panelDescriptionHelpId}
            {...form.register("description")}
          />
        </div>
        <div className={Styles.checkboxOptionContainer}>
          <CheckboxLabel
            label={
              <span className={Styles.checkboxLabel}>
                <FormattedMessage
                  id="11f4ca65-c276-4e94-9bb1-88158aefd10a"
                  defaultMessage="Notary assignment"
                />
              </span>
            }
            subLabel={
              <FormattedMessage
                id="4cb0be62-d0e6-46cd-99b1-83c8e9cc3fa2"
                defaultMessage="Assign a transaction to a notary before overflow"
              />
            }
            checkbox={
              <div className={Styles.optionCheckbox}>
                <Checkbox aria-invalid="false" {...register("allowsAssignment")} />
              </div>
            }
          />
          <CheckboxLabel
            label={
              <span className={Styles.checkboxLabel}>
                <FormattedMessage
                  id="b3629853-4f2b-4203-8f14-b48ec1ca9ee5"
                  defaultMessage="Panel notary overflow"
                />
              </span>
            }
            subLabel={
              <FormattedMessage
                id="567c60ac-3e35-4155-a518-ac655681c2d0"
                defaultMessage="Route unanswered calls to on-demand notaries"
              />
            }
            checkbox={
              <div className={Styles.optionCheckbox}>
                <Checkbox
                  name="panelOverflow"
                  aria-invalid="false"
                  checked={overflowActive}
                  onChange={() => setOverflowActive(!overflowActive)}
                />
              </div>
            }
          />
        </div>
        {overflowActive && <SelectOverflow form={form} />}
        {renderError()}
      </div>
    </WorkflowModal>
  );
}

export function EditPanelModal({ onCancel, onSave, panel }: EditProps) {
  const panelId = useId();
  return (
    <AddOrEditOrgPanelModal
      defaultValues={{
        routingExpiry: panel.routingExpiry,
        panelName: panel.name,
        description: panel.description,
        allowsAssignment: Boolean(panel.allowsAssignment),
      }}
      onCancel={onCancel}
      onSave={onSave}
      buttonLabel={
        <FormattedMessage id="786a8ec8-9643-42bc-947b-312f8e7b9789" defaultMessage="Save changes" />
      }
      title={
        <FormattedMessage id="1ad76842-93b0-4c8c-bac3-a4a617533d2b" defaultMessage="Edit panel" />
      }
    >
      <div>
        <span id={panelId} className={Styles.fieldHeading}>
          <FormattedMessage id="43981639-2bea-4c54-adca-d016a4d4fbe3" defaultMessage="Panel ID" />
        </span>
        <div aria-labelledby={panelId}>{panel.id}</div>
      </div>
    </AddOrEditOrgPanelModal>
  );
}

export function AddPanelModal({ onCancel, onSave, organization }: AddProps) {
  const organizationId = useId();
  return (
    <AddOrEditOrgPanelModal
      onCancel={onCancel}
      onSave={onSave}
      buttonLabel={
        <FormattedMessage id="dac6d42c-bc84-4994-acea-ea3074cda507" defaultMessage="Create panel" />
      }
      title={
        <FormattedMessage
          id="53c8c091-cd08-47b0-b863-50243b3be53e"
          defaultMessage="Create a panel"
        />
      }
    >
      <FormattedMessage
        id="dac6d42c-bc84-4994-acea-ea3074cda509"
        defaultMessage="Panels allow you to curate which notaries will be allowed to take calls that you send to signers"
      />
      <div>
        <span id={organizationId} className={Styles.fieldHeading}>
          <FormattedMessage
            id="a33ae7bf-95ea-4fd3-9d6e-ab5c809d6ce9"
            defaultMessage="Company Org ID"
          />
        </span>
        <div aria-labelledby={organizationId}>{organization.id}</div>
      </div>
    </AddOrEditOrgPanelModal>
  );
}

function SelectOverflow({ form }: SelectProps) {
  const intl = useIntl();
  useEffect(() => {
    return () => form.unregister("routingExpiry");
  }, []);

  return (
    <div>
      <AutomaticFormRow<FormValues>
        fullWidth
        form={form}
        name="routingExpiry"
        registerOptions={{
          required: defaultRequiredMessage(intl),
          pattern: { value: /^\d+$/, message: intl.formatMessage(MESSAGES.overFlowError) },
        }}
        as={NumberInput}
        label={
          <FormattedMessage
            id="f4159e21-031f-40c4-af81-6fb947fdb800"
            defaultMessage="Overflow threshold: Panel Notaries -> ODN (seconds)"
          />
        }
        placeholder={intl.formatMessage(MESSAGES.overFlowPlaceHolder)}
      />
    </div>
  );
}
