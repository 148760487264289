import classnames from "classnames";
import { FormattedMessage } from "react-intl";

import { ComboboxDropdown, ComboboxDropdownMenuOption } from "common/core/combobox_dropdown";
import { ProofReasonCategory } from "graphql_globals";

import { type ProofReasonCode } from "./context";
import Styles from "./proof_reason_codes.module.scss";
import { useReasonCodeDiff } from "./util";

const CategoriesColumn = {
  Header: (
    <span>
      <FormattedMessage id="6a2e36b8-ae03-4b69-aacb-c4259ff4430b" defaultMessage="Categories" />
    </span>
  ),
  render: (reasonCode: ProofReasonCode) => {
    return <CategoriesColumnCell reasonCode={reasonCode} />;
  },
  collapse: false,
};

function CategoriesColumnCell({ reasonCode }: { reasonCode: ProofReasonCode }) {
  const { canEdit, edited, displayValue, toggleCategory } = useReasonCodeDiff({
    reasonCode,
    fieldName: "categories",
  });

  const handleToggleCategory = (category: ProofReasonCategory) => {
    toggleCategory(reasonCode.id, category);
  };

  function renderDropdownOption(category: ProofReasonCategory) {
    return (
      <ComboboxDropdownMenuOption
        selected={displayValue.includes(category)}
        onChange={() => handleToggleCategory(category)}
        optionLabel={category}
        disabled={!canEdit}
      />
    );
  }

  return (
    <ComboboxDropdown
      className={classnames(Styles.categoriesWidth, edited && Styles.editedCell)}
      label={
        <FormattedMessage
          id="81361441-27c8-4962-a9fd-5591cd8c919e"
          defaultMessage="No categories selected"
        />
      }
      selectedLabel={displayValue.join(", ")}
      hasSelection={false} // don't want to show the clear button
      allowMultipleSelection
      noBorder
      noBackground
    >
      {() => (
        <>
          {renderDropdownOption(ProofReasonCategory.AGE)}
          {renderDropdownOption(ProofReasonCategory.EMAIL)}
          {renderDropdownOption(ProofReasonCategory.IDENTITY)}
        </>
      )}
    </ComboboxDropdown>
  );
}

export default CategoriesColumn;
