import { useEffect, useState, type ReactNode } from "react";
import { useSearchParams } from "react-router-dom";

import LoadingIndicator from "common/core/loading_indicator";
import OrgBrandTheme from "common/core/brand/org_brand_theme";
import useOrgBrandUnauthed from "common/core/brand/get_org_brand_unauthed";
import type { AuthCode } from "common/account/google_signin_button";
import { getAuthenticationTypes } from "common/account/login/util";

import EmailScreen, {
  type FormValues as EmailSubmitValues,
  type EmailScreenType,
  type OnAuthenticationFailed,
} from "./proof/screens/email";
import PasswordScreen, {
  type FormValues as PasswordSubmitValues,
  type PasswordScreenType,
  type MfaData,
} from "./proof/screens/password";
import SamlScreen, { type SSOScreenType } from "./proof/screens/saml";
import ProofBackground from "./proof/background";
import ForgotPasswordScreen, {
  type ForgotPasswordScreenType,
} from "./proof/screens/forgot_password";
import MFAScreen, { type MfaScreenType } from "./proof/screens/mfa";
import LoginAttemptsExceededScreen, {
  type LoginAttemptsExceededScreenType,
} from "./proof/screens/login_attempts_exceeded";
import ResetPasswordScreen, { type ResetPasswordScreenType } from "./proof/screens/reset_password";
import CreatePasswordScreen, {
  type CreatePasswordScreenType,
} from "./proof/screens/create_password";
import VerifyEmailScreen, { type VerifyEmailScreenType } from "./proof/screens/verify_email";
import VerifyEmailCodeScreen, {
  type VerifyEmailCodeScreenType,
} from "./proof/screens/verify_email_code";

type LoadingScreen = {
  type: "loading";
  email: string;
};

export type Screen =
  | ResetPasswordScreenType
  | ForgotPasswordScreenType
  | EmailScreenType
  | LoginAttemptsExceededScreenType
  | SSOScreenType
  | MfaScreenType
  | PasswordScreenType
  | CreatePasswordScreenType
  | VerifyEmailScreenType
  | VerifyEmailCodeScreenType
  | LoadingScreen;
type LoginProps = {
  loginAttemptsExceeded: boolean;
  onGoogleSignIn: (authCode: AuthCode) => void;
  loginWithPassword: (
    values: EmailSubmitValues & PasswordSubmitValues,
    passwordlessEnabled?: boolean,
    needsAccount?: boolean,
  ) => Promise<MfaData | undefined>;
  onClearErrors: () => void;
  webThemeColor?: string | null;
  onSuccess: () => void;
  emailFromParams: string;
  hideEmailFooter?: boolean;
  wrapper?: (props: { screen: Screen; children: ReactNode; onBack?: () => void }) => ReactNode;
  allowCreatePassword?: boolean;
  passwordlessEnabled?: boolean | undefined;
  bundleId?: string | undefined;
  passwordProps?: {
    withEmailReminder?: boolean;
    hideTos?: boolean;
    onGoogleSignIn?: (authCode: AuthCode) => void;
  };
  showTos?: boolean;
  disableEmail?: boolean;
  onBackScreenOverride?: Record<
    Screen["type"],
    EmailScreenType["type"] | PasswordScreenType["type"]
  >;
  analytics?: {
    onForgotPasswordClicked?: () => void;
    onAuthenticationFailed?: OnAuthenticationFailed;
  };
};

export const LOGIN_ATTEMPTS_EXCEEDED = "login_attempts_exceeded";

export function Login({
  onGoogleSignIn,
  loginWithPassword,
  loginAttemptsExceeded,
  onClearErrors,
  onSuccess: onMfaSuccess,
  emailFromParams,
  hideEmailFooter,
  showCard,
  allowCreatePassword,
  passwordlessEnabled,
  bundleId,
  passwordProps,
  wrapper,
  showTos = true,
  disableEmail,
  onBackScreenOverride,
  analytics,
}: LoginProps & { showCard?: boolean }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [screen, setScreen] = useState<Screen>(
    emailFromParams ? { type: "loading", email: emailFromParams } : { type: "email" },
  );
  const handleNextScreen = (screen: Screen) => {
    onClearErrors();
    setScreen(screen);
  };
  const loginAttemptsExceededOrForbidden =
    loginAttemptsExceeded || searchParams.get("forbidden") === "true";
  useEffect(() => {
    if (loginAttemptsExceededOrForbidden) {
      handleNextScreen({ type: LOGIN_ATTEMPTS_EXCEEDED, email: screen.email! });
    }
  }, [loginAttemptsExceededOrForbidden, screen.email]);
  if (emailFromParams && screen.type === "loading" && !loginAttemptsExceededOrForbidden) {
    getAuthenticationTypes({ email: emailFromParams })
      .then((response) => {
        if (response.authenticationTypes.includes("sso")) {
          setScreen({
            type: "sso",
            email: emailFromParams,
            ssoProvider: response.ssoProvider!,
            passwordAuthEnabled: response.authenticationTypes.includes("password"),
          });
        } else if (!response.authenticationTypes.length && allowCreatePassword) {
          setScreen({ type: "create_password", password: "", email: emailFromParams });
        } else {
          setScreen({ type: "password", password: "", email: emailFromParams });
        }
      })
      .catch(() => {
        setScreen({ type: "email" });
      });
  }

  const onBackToEmail = () => {
    const type = onBackScreenOverride?.[screen.type] || "email";
    handleNextScreen({ type, email: screen.email } as EmailScreenType | PasswordScreenType);
  };

  switch (screen.type) {
    case "email": {
      return (
        <EmailScreen
          email={screen.email}
          onGoogleSignIn={onGoogleSignIn}
          onPasswordLogin={loginWithPassword}
          onNextScreen={handleNextScreen}
          hideFooter={hideEmailFooter}
          showCard={showCard}
          wrapper={wrapper ? (children: ReactNode) => wrapper({ children, screen }) : undefined}
          allowCreatePassword={allowCreatePassword}
          passwordlessEnabled={passwordlessEnabled}
          bundleId={bundleId}
          showTos={showTos}
          disableEmail={disableEmail}
          onAuthenticationFailed={analytics?.onAuthenticationFailed}
        />
      );
    }
    case "password": {
      const onForgotPassword = () => {
        handleNextScreen({ type: "forgot_password", email: screen.email });
        analytics?.onForgotPasswordClicked?.();
      };
      return (
        <PasswordScreen
          onPasswordLogin={loginWithPassword}
          email={screen.email}
          onNextScreen={handleNextScreen}
          showCard={showCard}
          onBack={onBackToEmail}
          onForgotPassword={onForgotPassword}
          wrapper={
            wrapper
              ? (children: ReactNode) => wrapper({ children, screen, onBack: onBackToEmail })
              : undefined
          }
          onAuthenticationFailed={analytics?.onAuthenticationFailed}
          {...passwordProps}
        />
      );
    }
    case "sso": {
      return (
        <SamlScreen
          passwordAuthEnabled={screen.passwordAuthEnabled}
          onNextScreen={handleNextScreen}
          email={screen.email}
          ssoProvider={screen.ssoProvider}
          showCard={showCard}
          onBack={onBackToEmail}
          wrapper={
            wrapper
              ? (children: ReactNode) => wrapper({ children, screen, onBack: onBackToEmail })
              : undefined
          }
        />
      );
    }
    case "mfa": {
      return (
        <MFAScreen
          authOptions={screen.authOptions}
          onMfaSuccess={onMfaSuccess}
          password={screen.password}
          showCard={showCard}
          onBack={onBackToEmail}
          wrapper={
            wrapper
              ? (children: ReactNode) => wrapper({ children, screen, onBack: onBackToEmail })
              : undefined
          }
        />
      );
    }
    case "forgot_password": {
      const onBack = () => {
        onBackToEmail();
        analytics?.onForgotPasswordClicked?.();
      };
      return (
        <ForgotPasswordScreen
          email={screen.email}
          showCard={showCard}
          onBack={onBack}
          wrapper={
            wrapper ? (children: ReactNode) => wrapper({ children, screen, onBack }) : undefined
          }
        />
      );
    }
    case "login_attempts_exceeded": {
      const newSearchParamsWithoutForbidden = () => {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.delete("forbidden");
        setSearchParams(newSearchParams, { replace: true });
      };

      const onBack = () => {
        newSearchParamsWithoutForbidden();
        onBackToEmail();
      };

      return (
        <LoginAttemptsExceededScreen
          email={screen.email}
          onNextScreen={handleNextScreen}
          onBack={onBack}
          wrapper={
            wrapper ? (children: ReactNode) => wrapper({ children, screen, onBack }) : undefined
          }
          showCard
        />
      );
    }
    case "reset_password": {
      return (
        <ResetPasswordScreen
          showCard={showCard}
          onBack={onBackToEmail}
          wrapper={
            wrapper
              ? (children: ReactNode) => wrapper({ children, screen, onBack: onBackToEmail })
              : undefined
          }
        />
      );
    }
    case "create_password": {
      return (
        <CreatePasswordScreen
          onPasswordLogin={loginWithPassword}
          email={screen.email}
          verificationCode={screen.verificationCode}
          onNextScreen={handleNextScreen}
          showCard={showCard}
          onBack={onBackToEmail}
          onAuthenticationFailed={analytics?.onAuthenticationFailed}
          wrapper={
            wrapper
              ? (children: ReactNode) => wrapper({ children, screen, onBack: onBackToEmail })
              : undefined
          }
        />
      );
    }
    case "verify_email": {
      return (
        <VerifyEmailScreen
          bundleId={screen.bundleId}
          email={screen.email}
          showCard={showCard}
          onBack={onBackToEmail}
          wrapper={
            wrapper
              ? (children: ReactNode) => wrapper({ children, screen, onBack: onBackToEmail })
              : undefined
          }
        />
      );
    }
    case "verify_email_code": {
      return (
        <VerifyEmailCodeScreen
          email={screen.email}
          showCard={showCard}
          onBack={onBackToEmail}
          onNextScreen={handleNextScreen}
          wrapper={
            wrapper
              ? (children: ReactNode) => wrapper({ children, screen, onBack: onBackToEmail })
              : undefined
          }
        />
      );
    }
    case "loading":
      return <LoadingIndicator />;
  }
}

export default function ThemedLogin(props: LoginProps) {
  const [searchParams] = useSearchParams();
  const transactionId = searchParams.get("transaction_id");
  const organizationName = searchParams.get("organization_name");
  const { brandLoading, brand } = useOrgBrandUnauthed(transactionId);
  if (brandLoading) {
    return <LoadingIndicator />;
  }

  return (
    <OrgBrandTheme theme={brand ?? {}}>
      <ProofBackground brand={brand} organizationName={organizationName}>
        <Login {...props} allowCreatePassword={false} showCard />
      </ProofBackground>
    </OrgBrandTheme>
  );
}
