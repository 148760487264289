import { useProofReasonCodes, type ProofReasonCode, type ReasonCodeEdit } from "./context";

export function useReasonCodeDiff<F extends keyof ReasonCodeEdit & keyof ProofReasonCode>({
  reasonCode,
  fieldName,
}: {
  reasonCode: ProofReasonCode;
  fieldName: F;
}) {
  const { canEdit, edits, ...rest } = useProofReasonCodes();
  const editedValue = edits[reasonCode.id]?.[fieldName];
  const originalValue = reasonCode[fieldName];
  const displayValue = editedValue ?? originalValue;
  const edited = editedValue !== undefined;

  return {
    canEdit,
    edits,
    editedValue,
    originalValue,
    displayValue,
    edited,
    ...rest,
  };
}
