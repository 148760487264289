import { FormattedMessage } from "react-intl";
import type { ReactNode } from "react";

import { Paragraph } from "common/core/typography";

import { BackButtonOnlyFooter } from "./common";
import ProofCard from "../card";

export type ResetPasswordScreenType = {
  type: "reset_password";
  email: string;
};

type Props = {
  onBack: () => void;
  showCard?: boolean;
  wrapper?: (children: ReactNode) => ReactNode;
};

function ResetPassword() {
  return (
    <Paragraph>
      <FormattedMessage
        id="4e6b10ff-b445-4140-86b6-55bc289ee1ea"
        defaultMessage="A link has been sent to your email to reset your password."
      />
    </Paragraph>
  );
}

export default function ResetPasswordScreen({ onBack, showCard, wrapper }: Props) {
  const content = <ResetPassword />;

  if (showCard) {
    return <ProofCard body={content} footer={<BackButtonOnlyFooter onClick={onBack} />} />;
  }
  return <>{wrapper ? wrapper(content) : content}</>;
}
