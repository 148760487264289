import "./index.scss";

import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import { useLogout } from "common/authentication";
import { userFullName } from "util/user";
import { segmentTrack } from "util/segment";
import { EVENT } from "constants/analytics";
import { openSupportChat, StandaloneChat, useSupportChatDisabled } from "common/support/chat";
import { Feature } from "graphql_globals";
import { isIpad } from "util/support";
import { SETTINGS_PATH } from "util/routes";

import type { AccountHoverMenuUser as User } from "../user_fragment.graphql";
import { HeaderDropdownItem } from "./dropdown_item";
import { HeaderDropdown } from "./dropdown";
import { SendManageButton } from "../buttons/send_manage_button";
import Styles from "./minimal_nav.module.scss";

type Props = {
  user: User;
};

function MinimalNav({ user }: Props) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const logout = useLogout();
  const navigate = useNavigate();
  const supportchatDisabled = useSupportChatDisabled();

  function goToCustomerProfile() {
    segmentTrack(EVENT.ACCESSED_PROFILE_SETTINGS);
    navigate(SETTINGS_PATH);
  }

  return (
    <>
      {user.organization?.featureList.includes(Feature.SIGN_ON_DEVICE) && isIpad() && (
        <SendManageButton user={user} className={Styles.sendManageLink} />
      )}
      <HeaderDropdown
        namespace="AccountMenu"
        title={userFullName(user)}
        automationId="account-menu-header"
        withCaret
        onToggle={() => setDropdownOpen(!dropdownOpen)}
        isOpen={dropdownOpen}
      >
        <HeaderDropdownItem onClick={goToCustomerProfile} automationId="profile-button">
          <FormattedMessage
            id="b8c926b4-44d9-4b35-bd98-1dc95303686f"
            defaultMessage="Account settings"
          />
        </HeaderDropdownItem>
        {!supportchatDisabled && (
          <HeaderDropdownItem
            isButton
            onClick={() => openSupportChat()}
            automationId="support-button"
          >
            <FormattedMessage
              id="7944df4f-6d3e-4fee-8d50-34a44d1f35e2"
              defaultMessage="Chat with support"
            />
          </HeaderDropdownItem>
        )}
        <HeaderDropdownItem onClick={logout} automationId="logout-button">
          <FormattedMessage id="6d3a04a6-c3a0-497a-97ca-2297bc5d1d77" defaultMessage="Log out" />
        </HeaderDropdownItem>
      </HeaderDropdown>
      <StandaloneChat showSupportButton={false} />
    </>
  );
}

export default MinimalNav;
