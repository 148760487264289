import "./index.scss";

import { useState } from "react";
import { FormattedMessage } from "react-intl";

import type { ViewerDefaultAppFrame_user as User } from "common/app_frame/default_fragment.graphql";
import { useMutation } from "util/graphql";
import { useLogout } from "common/authentication";
import { captureException } from "util/exception";
import { hardNavigateTo } from "util/navigation";
import { userFullName } from "util/user";
import { segmentTrack } from "util/segment";
import { SETTINGS_PATH, SIGN_PATH } from "util/routes";
import { DEFAULT_ROUTE } from "common/organization/member_management/routing";
import { isNotaryNST } from "common/notary/capacity";
import { redirectToSubdomain, redirectUrl } from "util/application_redirect";
import CreateOrganizationMutation from "util/apollo_graphql/mutations/create_organization/mutation.graphql";
import AppSubdomains, { type Subdomain } from "constants/app_subdomains";
import { KNOWLEDGE_AT_NOTARIZE_URL } from "constants/support";
import { EVENT } from "constants/analytics";
import {
  NOTARIZE_ACADEMY_URL,
  BUSINESS_MARKETING_RESOURCE_URL,
  NOTARY_MARKETING_RESOURCE_URL,
} from "constants/marketing";
import { PATH as NOTARY_MEETING_HISTORY_PATH } from "common/notary/meeting_history/path";
import { Feature, NotaryOnboardingStatuses, OrganizationAccountCreation } from "graphql_globals";
import { openSupportChat, StandaloneChat, useSupportChatDisabled } from "common/support/chat";
import { saveUserDevicePreferences } from "util/device_preferences";
import ClickOutside from "common/core/click_outside";
import { useHistoryPath } from "common/closing_agenda";
import { API_KEYS_SETTINGS_ROOT_PREFIX } from "common/settingsv2/sidebar_settings/api_keys";
import { usePermissions } from "common/core/current_user_role";
import { ACCOUNT_TYPES } from "common/account/constants";

import { HeaderDropdownItem, HeaderDropdownItemIcon } from "./dropdown_item";
import { HeaderDropdown } from "./dropdown";

const TEMPLATE_PATH = "/templates";
const API_PATH = `/settings/${API_KEYS_SETTINGS_ROOT_PREFIX}`;
const NOTARY_PROFILE_PATH = "/settings/notary/profile";
const NOTARY_ONBOARDING_PATH = "/settings/notary/onboarding";
const TECHCHECK_PATH = "/tech-check/video";

type Props = {
  // User can not be required since Guest Upload uses this header
  user?: User;
  portal: Subdomain;
};

export function AccountMenus({ user, portal }: Props) {
  const logout = useLogout();
  const supportchatDisabled = useSupportChatDisabled();
  const historyPath = useHistoryPath(NOTARY_MEETING_HISTORY_PATH);
  const createOrganization = useMutation(CreateOrganizationMutation);
  const [resourcesDropdownOpen, setResourcesDropdownOpen] = useState(false);
  const [settingsDropdownOpen, setSettingsDropdownOpen] = useState(false);
  const { id, organization, email, featureList, notaryProfile } = user || {};

  const isTitlePortal = portal === AppSubdomains.title_agency;
  const { hasPermissionFor } = usePermissions();
  const showTemplates = hasPermissionFor("viewOrganizationTransactions");

  const fullName = userFullName(user);
  const businessName = organization?.name;
  const tierFeatures = featureList || organization?.featureList || [];
  const showTeam =
    tierFeatures.includes(Feature.TEAM_MANAGEMENT) && hasPermissionFor("viewTeamDetails");
  const showApiKeys =
    tierFeatures.includes(Feature.PARTNER_API) &&
    tierFeatures.includes(Feature.API_KEY_MANAGEMENT) &&
    hasPermissionFor("viewOrganizationApiKeys");
  const isNst = isNotaryNST(notaryProfile ?? null);
  const hasNotaryProfile = Boolean(notaryProfile);
  const accountMenuTitle = fullName || businessName || email;
  const isBusinessAccount = Boolean(
    user?.accountTypes?.map((t) => t.toLowerCase()).includes(ACCOUNT_TYPES.BUSINESS),
  );
  const showNotarizeAcademyLink = isTitlePortal || isBusinessAccount;

  async function createOrganizationHelper() {
    if (!organization) {
      try {
        await createOrganization({
          variables: {
            input: {
              userId: id,
              accountCreation: OrganizationAccountCreation.USER_INITIATED_UPGRADE,
            },
          },
        });
      } catch (error) {
        captureException(error);
        return Promise.reject(error);
      }
    }
    return Promise.resolve();
  }

  function goToPortal() {
    if (portal === AppSubdomains.customer) {
      return AppSubdomains.business;
    }
    return portal;
  }

  function goToAccount() {
    createOrganizationHelper();
    segmentTrack(EVENT.ACCESSED_ACCOUNT_SETTINGS);
    hardNavigateTo(SETTINGS_PATH);
  }

  function goToNotaryProfile(onboardingStatus?: NotaryOnboardingStatuses) {
    segmentTrack(EVENT.ACCESSED_NOTARY_PROFILE_SETTINGS);
    redirectToSubdomain(
      AppSubdomains.business,
      onboardingStatus === NotaryOnboardingStatuses.COMPLETED
        ? NOTARY_PROFILE_PATH
        : NOTARY_ONBOARDING_PATH,
    );
  }

  function goToNotaryJournal() {
    redirectToSubdomain(AppSubdomains.business, "/journal");
  }

  function goToNotaryMeetingHistory() {
    redirectToSubdomain(AppSubdomains.business, historyPath);
  }

  function goToTeam() {
    createOrganizationHelper();
    redirectToSubdomain(goToPortal(), DEFAULT_ROUTE);
  }

  function openNotarizeAcademy(isBusinessAccount: boolean, isNotary: boolean) {
    if (isNotary) {
      window.open(KNOWLEDGE_AT_NOTARIZE_URL);
    } else if (isBusinessAccount) {
      window.open("https://www.notarize.com/academy/business-partner/product-onboarding/thank-you");
    } else {
      const location = organization?.placeAnOrderEnabled
        ? NOTARIZE_ACADEMY_URL
        : "https://www.notarize.com/academy/title-agent/product-onboarding/thank-you";
      window.open(location);
    }
  }

  function goToTemplates() {
    createOrganizationHelper();
    redirectToSubdomain(goToPortal(), TEMPLATE_PATH);
  }

  function goToApiKeys() {
    createOrganizationHelper();
    redirectToSubdomain(goToPortal(), API_PATH);
  }

  function goToNotary() {
    window.open(redirectUrl(AppSubdomains.notary), "_blank");
  }

  function goToTechCheck() {
    // clear current tech check related data
    saveUserDevicePreferences({
      webcamDeviceId: null,
      microphoneDeviceId: null,
      speakerDeviceId: null,
    });
    redirectToSubdomain(AppSubdomains.business, TECHCHECK_PATH);
  }

  function goToSignerAccount() {
    redirectToSubdomain(AppSubdomains.customer, SIGN_PATH);
  }

  const teamItem = (
    <HeaderDropdownItem onClick={goToTeam} automationId="team-button">
      <FormattedMessage id="845778d4-8736-4d3b-b8f7-038258d45dec" defaultMessage="Team" />
    </HeaderDropdownItem>
  );

  const notarizeAcademyLinkItem = (
    <HeaderDropdownItem
      onClick={() => openNotarizeAcademy(Boolean(isBusinessAccount), Boolean(notaryProfile))}
      automationId="notarize-academy-button"
    >
      <FormattedMessage
        id="c26b2715-2d20-4af3-a800-d8702eb0bfbe"
        defaultMessage="Knowledge@Notarize"
      />
    </HeaderDropdownItem>
  );

  const templatesItem = (
    <HeaderDropdownItem onClick={goToTemplates} automationId="templates-button">
      <FormattedMessage id="81a11ae5-5f18-4edc-b391-ad255cbf3c3d" defaultMessage="Templates" />
    </HeaderDropdownItem>
  );

  return (
    <ClickOutside
      onClickOutside={() => {
        setResourcesDropdownOpen(false);
        setSettingsDropdownOpen(false);
      }}
    >
      <div className="Menus">
        {(showTeam ||
          isBusinessAccount ||
          showTemplates ||
          showApiKeys ||
          showNotarizeAcademyLink ||
          hasNotaryProfile ||
          isNst) && (
          <HeaderDropdown
            namespace="ResourceMenu"
            title="Resources"
            titleSecondary={null}
            automationId="resource-menu-header"
            withCaret
            onToggle={() => {
              setSettingsDropdownOpen(false);
              setResourcesDropdownOpen(!resourcesDropdownOpen);
            }}
            isOpen={resourcesDropdownOpen}
          >
            {showTeam && teamItem}

            {showNotarizeAcademyLink && notarizeAcademyLinkItem}

            {showTemplates && templatesItem}

            {showApiKeys && (
              <HeaderDropdownItem onClick={goToApiKeys} automationId="api-button">
                <FormattedMessage
                  id="4d32722d-1fcd-4119-ab70-f2bca6af0feb"
                  defaultMessage="API Keys"
                />
              </HeaderDropdownItem>
            )}

            {isBusinessAccount && (
              <HeaderDropdownItem
                onClick={() => {
                  window.open(
                    isNst ? NOTARY_MARKETING_RESOURCE_URL : BUSINESS_MARKETING_RESOURCE_URL,
                  );
                }}
                automationId="marketing-resource-button"
              >
                <FormattedMessage
                  id="216211f1-f9cd-4ca6-ad7a-05e949313088"
                  defaultMessage="Marketing Resources"
                />
              </HeaderDropdownItem>
            )}

            {hasNotaryProfile && (
              <>
                <HeaderDropdownItem
                  onClick={goToNotaryJournal}
                  automationId="notary-journal-button"
                >
                  <FormattedMessage
                    id="39e5b9b9-f9ad-4530-a06e-9969278adb13"
                    defaultMessage="Notary Journal"
                  />
                </HeaderDropdownItem>

                <HeaderDropdownItem
                  onClick={goToNotaryMeetingHistory}
                  automationId="notary-meeting-history"
                >
                  <FormattedMessage
                    id="818b9207-737b-4299-941b-e15d8877cc65"
                    defaultMessage="Meeting History"
                  />
                </HeaderDropdownItem>
              </>
            )}

            {isNst && (
              <HeaderDropdownItem onClick={goToSignerAccount} automationId="my-signer-account">
                <FormattedMessage
                  id="81915e2b-c2a9-4e85-8764-3c264f5b472b"
                  defaultMessage="Meet with a Notary"
                />
              </HeaderDropdownItem>
            )}
          </HeaderDropdown>
        )}

        <HeaderDropdown
          namespace="AccountMenu"
          withCaret
          title={accountMenuTitle}
          automationId="account-menu-header"
          onToggle={() => {
            setResourcesDropdownOpen(false);
            setSettingsDropdownOpen(!settingsDropdownOpen);
          }}
          isOpen={settingsDropdownOpen}
        >
          <HeaderDropdownItem onClick={goToAccount} automationId="account-button">
            <FormattedMessage
              id="2ecfe797-1d2f-4f0a-a833-fc5361af9fd2"
              defaultMessage="Account settings"
            />
          </HeaderDropdownItem>
          {hasNotaryProfile && (
            <>
              <HeaderDropdownItem
                onClick={() => goToNotaryProfile(notaryProfile?.onboardingStatus)}
                automationId="notary-profile-button"
              >
                <FormattedMessage
                  id="42d6c620-264f-4f87-9649-e6a656db6b65"
                  defaultMessage="Notary profile"
                />
              </HeaderDropdownItem>
              <HeaderDropdownItem onClick={() => goToTechCheck()} automationId="tech-check-button">
                <FormattedMessage
                  id="6151a9b1-8c0a-4666-96f0-62c5d8b7a17e"
                  defaultMessage="A/V settings"
                />
              </HeaderDropdownItem>
            </>
          )}

          {!supportchatDisabled && (
            <HeaderDropdownItem
              isButton
              onClick={() => openSupportChat()}
              automationId="support-button"
            >
              <FormattedMessage
                id="7944df4f-6d3e-4fee-8d50-34a44d1f35e2"
                defaultMessage="Chat with support"
              />
            </HeaderDropdownItem>
          )}

          {!isNst && hasNotaryProfile && (
            <HeaderDropdownItem onClick={goToNotary} automationId="notary-portal-button">
              <FormattedMessage
                id="3a7df31f-77ae-490a-8c98-e921086a45b7"
                defaultMessage="Go to notary account"
              />
              <HeaderDropdownItemIcon name="new-window" />
            </HeaderDropdownItem>
          )}

          <HeaderDropdownItem
            onClick={logout}
            automationId="logout-button"
            className="HeaderDropdownDivider"
          >
            <FormattedMessage id="6d3a04a6-c3a0-497a-97ca-2297bc5d1d77" defaultMessage="Log out" />
          </HeaderDropdownItem>
        </HeaderDropdown>
        <StandaloneChat showSupportButton={false} />
      </div>
    </ClickOutside>
  );
}
