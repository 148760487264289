import type { ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import { CardSection } from "common/core/card";
import FormattedPrice from "common/core/format/formatted_price";
import {
  GRANDFATHERED_PROFESSIONAL_PRICE_ITEMS,
  REAL_TIER_PRICE_ITEMS,
  SMB_TIER_PRICE_ITEMS,
} from "constants/tier";
import { compact } from "util/object";
import camelizeObject from "util/camelize_object";

import CommonStyles from "../index.module.scss";
import type {
  AdminCompanyDetailsOrganization_organization_Organization_scheduledTiers_prices as Prices,
  AdminCompanyDetailsOrganization_organization_Organization_scheduledTiers_options as Options,
} from "../organization_query.graphql";

type Props = {
  tier: string;
  prices: Prices;
  options?: Options | null;
  pricingDisplayNames: Record<string, ReactNode | undefined>;
  optionDisplayNames: Record<string, ReactNode | undefined>;
  showHeaders: boolean;
  pricesJson: string;
};

function getTierPriceItems(tier: string) {
  if (tier.includes("TITLE") || tier.includes("LENDER")) {
    return REAL_TIER_PRICE_ITEMS;
  }
  if (tier.includes("GRANDFATHERED_PROFESSIONAL")) {
    return GRANDFATHERED_PROFESSIONAL_PRICE_ITEMS;
  }
  return SMB_TIER_PRICE_ITEMS;
}

function cleanObject(obj: Record<string, { toString: () => string } | null>) {
  return compact<string, { toString: () => string } | null>({ ...obj, __typename: null });
}

export default function TierDetails({
  tier,
  prices,
  options = null,
  pricingDisplayNames,
  optionDisplayNames,
  showHeaders,
  pricesJson,
}: Props) {
  const formattedPrices = Object.entries(cleanObject(prices));
  const formattedOptions = options ? Object.entries(cleanObject(options)) : null;
  const tierPriceItems = Object.values(getTierPriceItems(tier));
  const originalPrices = new Map(
    Object.entries(
      JSON.parse(pricesJson, camelizeObject) as ArrayLike<unknown> | Record<string, unknown>,
    ),
  );
  const missmatchedPricingDisplayNames = {
    notaverseEsignDoc: "notaverseEsignedDoc",
    refinance: "refinanceTransaction",
    purchaseSeller: "purchaseSellerTransaction",
    purchaseBuyerCash: "purchaseBuyerCashTransaction",
    purchaseBuyerLoan: "purchaseBuyerLoanTransaction",
    loanModBorrower: "loanModBorrowerTransaction",
    other: "otherTransaction",
    heloc: "helocTransaction",
    notaverseRefinance: "notaverseRefinanceTransaction",
    notaversePurchaseSeller: "notaversePurchaseSellerTransaction",
    notaversePurchaseBuyerCash: "notaversePurchaseBuyerCashTransaction",
    notaversePurchaseBuyerLoan: "notaversePurchaseBuyerLoanTransaction",
    notaverseLoanModBorrower: "notaverseLoanModBorrowerTransaction",
    notaverseOther: "notaverseOtherTransaction",
    notaverseHELOC: "notaverseHelocTransaction",
    hybridRefinance: "hybridRefinanceTransaction",
    hybridTrailing: "hybridTrailingDocsTransaction",
    hybridPurchaseSeller: "hybridPurchaseSellerTransaction",
    hybridPurchaseBuyerCash: "hybridPurchaseBuyerCashTransaction",
    hybridPurchaseBuyerLoan: "hybridPurchaseBuyerLoanTransaction",
    hybridHeloc: "hybridHelocTransaction",
    hybridOther: "hybridOtherTransaction",
    additionalPurchaseBuyerLoanNsaMeeting: "additionalPurchaseBuyerLoadNsaMeeting",
    additionalHELOCNsaMeeting: "additionalHelocNsaMeeting",
    notaverseAdditionalPurchaseBuyerLoanNsaMeeting:
      "notaverseAdditionalPurchaseBuyerLoadNsaMeeting",
    notaverseAdditionalHELOCNsaMeeting: "notaverseAdditionalHelocNsaMeeting",
  };
  const missmatchedPricingDisplayNamesMap = new Map(Object.entries(missmatchedPricingDisplayNames));

  return (
    <CardSection>
      <div className={CommonStyles.tableSection}>
        {showHeaders && (
          <div className={CommonStyles.tableHeading}>
            <FormattedMessage id="5b0da634-6d7d-43d2-ba32-a2c7ec1bdf18" defaultMessage="Pricing" />
          </div>
        )}
        {formattedPrices
          .filter(([lineItem]) => tierPriceItems.includes(lineItem))
          .map(([key, value]) => {
            return (
              <div key={key} className={CommonStyles.pricingRow}>
                {pricingDisplayNames[key] || key}
                <div className={CommonStyles.price}>
                  {originalPrices.has(key) ||
                  originalPrices.has(missmatchedPricingDisplayNamesMap.get(key)!) ||
                  parseInt(value.toString(), 10) > 0 ? (
                    <FormattedPrice cents={value} />
                  ) : (
                    "not specified"
                  )}
                </div>
              </div>
            );
          })}
      </div>
      {formattedOptions && (
        <div className={CommonStyles.tableSection}>
          {showHeaders && (
            <div className={CommonStyles.tableHeading}>
              <FormattedMessage
                id="18344d49-e000-4c65-9706-f1994b4906a2"
                defaultMessage="Options"
              />
            </div>
          )}
          {formattedOptions.map(([key, value]) => {
            return (
              <div key={key} className={CommonStyles.pricingRow}>
                {optionDisplayNames[key] || key}
                <div className={CommonStyles.price}>
                  {key === "maxTransactionPrice" ? (
                    <FormattedPrice cents={value} />
                  ) : (
                    value.toString()
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </CardSection>
  );
}
