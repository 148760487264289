import { FormattedMessage } from "react-intl";
import { useState } from "react";

import { useMutation } from "util/graphql";
import { isGraphQLError } from "util/graphql/query";
import { captureException } from "util/exception";
import { CardHeading } from "common/core/card";
import BinaryToggle from "common/form/inputs/binary_toggle";
import Tooltip from "common/core/tooltip";
import Icon from "common/core/icon";
import { usePermissions } from "common/core/current_user_role";

import ToggleOrganizationDocumentVisibilityFlagMutation, {
  type ToggleOrganizationDocumentVisibilityFlag_toggleOrganizationDocumentVisibilityFlag_organization as Organization,
} from "./toggle_organization_document_visibility_flag_mutation.graphql";

type Props = {
  organization: Organization;
};

export default function DocVisibilityToggle({ organization }: Props) {
  const toggleOrganizationDocVisibilityFlagMutateFn = useMutation(
    ToggleOrganizationDocumentVisibilityFlagMutation,
  );

  const { hasPermissionFor } = usePermissions();
  const canToggleDocVisibility = hasPermissionFor("editCompanyDetailsDocVisibility");

  const [changeInProgress, setChangeInProgress] = useState(false);
  const [error, setError] = useState("");

  const toggleDocVisibility = () => {
    setChangeInProgress(true);
    setError("");

    toggleOrganizationDocVisibilityFlagMutateFn({
      variables: {
        input: {
          id: organization.id,
        },
      },
    })
      .catch((error: Error) => {
        if (!isGraphQLError(error)) {
          captureException(error);
        }
        const err = isGraphQLError(error) ? error.graphQLErrors[0] : error;
        setError(err.message);
      })
      .finally(() => {
        setChangeInProgress(false);
      });
  };

  return (
    <>
      <CardHeading>
        <span id="doc-visibility-toggle">
          <FormattedMessage
            id="9de4b12f-5180-41cd-b6ff-b7fb86c5946b"
            defaultMessage="Limit document visibility"
          />
        </span>
        &nbsp;
        <Tooltip target={<Icon name="question" />} placement="right">
          <FormattedMessage
            id="9b2e3e87-fbdc-4481-8b05-465caf106c31"
            defaultMessage="Limiting document visibility ensures signers will only see documents containing signatures, initials or data entry fields assigned to them."
          />
        </Tooltip>
      </CardHeading>
      <BinaryToggle
        aria-labelledby="doc-visibility-toggle"
        onChange={toggleDocVisibility}
        value={organization.docVisibilityFlag}
        disabled={changeInProgress || !canToggleDocVisibility}
        automationId="doc-visibility"
      />
      {error && (
        <div className="Form-row validation-failed">
          <div className="validation-message">{error}</div>
        </div>
      )}
    </>
  );
}
