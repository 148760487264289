import { FormattedMessage } from "react-intl";

import { Container } from "common/core/responsive";
import { useQuery } from "util/graphql";
import { Feature } from "graphql_globals";
import LoadingIndicator from "common/core/loading_indicator";
import { SettingsTitle } from "common/settingsv2/common";
import { KeystoneFullAccountPaymentHistory } from "common/settingsv2/sidebar_settings/billing/recent_payments/full_payments";

import RecentPaymentsQuery, {
  type RecentPayments_node_Organization as RecentPaymentsOrganization,
} from "./recent_payments.graphql";
import type { AdminCompanyDetailsOrganization_organization_Organization as Organization } from "../organization_query.graphql";

export function AdminCompanyRecentPayments(props: { organization: Organization }) {
  const { data, loading } = useQuery(RecentPaymentsQuery, {
    variables: { organizationId: props.organization.id },
  });
  if (loading) {
    return <LoadingIndicator />;
  }
  const organization = data!.node as RecentPaymentsOrganization;
  const showCoveredFor = organization.hasCoveredCharges;
  const hasCustomFees = organization.featureList.includes(Feature.CUSTOM_ACCOUNT_PRICING);
  return (
    <Container fluid style={{ width: "100%" }}>
      <SettingsTitle>
        {hasCustomFees ? (
          <FormattedMessage
            id="adfb4232-6c60-40ea-b13d-aa24c903c0c0"
            defaultMessage="Recent Platform & Transaction Fees"
          />
        ) : (
          <FormattedMessage
            id="5b20bdfb-a36e-4f00-baf9-6689250eabed"
            defaultMessage="Recent Payments"
          />
        )}
      </SettingsTitle>
      <KeystoneFullAccountPaymentHistory
        hasCustomFees={hasCustomFees}
        showCoveredFor={showCoveredFor}
      />
    </Container>
  );
}
