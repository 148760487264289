import "./index.scss";

import { useState, useCallback } from "react";

import store from "redux/store";
import Button from "common/core/button";
import Icon from "common/core/icon";
import MultiSelectInput from "common/form/inputs/multi_select";
import SelectInput from "common/form/inputs/select";
import { ERROR_TYPES } from "constants/errors";
import { addError } from "redux/actions/errors";

import { type Options, type MortgageClosingStatePreferenceHook, optionsToValues } from "./util";

type AddProps = {
  propertyStateOptions: Options[];
  addClosingStatePreference: MortgageClosingStatePreferenceHook["addClosingStatePreference"];
  onReset(): void;
  usStateListOptions: Options[];
};

export default function AddClosingStatePreferenceForm({
  propertyStateOptions,
  addClosingStatePreference,
  onReset,
  usStateListOptions,
}: AddProps) {
  const [newPreferenceClosingStateId, setNewPreferenceClosingStateId] = useState<string | null>(
    null,
  );
  const [newPreferenceNotaryStateOptions, setNewPreferenceNotaryStateOptions] = useState<Options[]>(
    [],
  );
  const handleReset = useCallback(() => {
    setNewPreferenceClosingStateId(null);
    setNewPreferenceNotaryStateOptions([]);
    onReset();
  }, []);

  return (
    <form
      className="SpecificStateTable--Form"
      data-automation-id="add-specific-state-form"
      onSubmit={(event) => {
        event.preventDefault();
        const newPreferenceNotaryStateIDs = optionsToValues(newPreferenceNotaryStateOptions);
        if (!newPreferenceClosingStateId) {
          throw new Error("Attempted to add a closing state preference without a closing state id");
        }
        addClosingStatePreference(newPreferenceClosingStateId, newPreferenceNotaryStateIDs)
          .then(handleReset)
          .catch((error: Error) => {
            store.dispatch(addError(error.message, ERROR_TYPES.REGULAR));
          });
      }}
    >
      <div className="SpecificStateTable--Row--Item">
        <SelectInput
          automationId="available-us-states"
          placeholder="US state"
          items={propertyStateOptions}
          value={newPreferenceClosingStateId}
          onChange={setNewPreferenceClosingStateId}
        />
      </div>
      <div className="SpecificStateTable--Row--Item">
        <MultiSelectInput
          automationId="allowed-closing-states-add"
          placeholder="Allowed closing states"
          items={usStateListOptions}
          value={newPreferenceNotaryStateOptions}
          onChange={setNewPreferenceNotaryStateOptions}
        />
      </div>
      <div className="SpecificStateTable--Row--Item">
        <Button
          variant="tertiary"
          buttonColor="danger"
          buttonSize="condensed"
          onClick={onReset}
          automationId="add-specific-state-cancel-button"
          aria-label="delete"
        >
          <Icon name="delete" className="SpecificStateTable--Row--Item--img" />
        </Button>
        <Button
          disabled={!newPreferenceClosingStateId || newPreferenceNotaryStateOptions.length === 0}
          variant="tertiary"
          buttonColor="action"
          buttonSize="condensed"
          type="submit"
          automationId="add-specific-state-save-button"
        >
          <Icon name="success" className="SpecificStateTable--Row--Item--img" />
        </Button>
      </div>
    </form>
  );
}
