import { FormattedMessage } from "react-intl";

import AlertMessage from "common/core/alert_message";
import Button from "common/core/button";
import LoadingIndicator from "common/core/loading_indicator";
import WorkflowModal from "common/modals/workflow_modal";

import ReviewDiffs from "./review_diffs";

type Props = {
  isOpen: boolean;
  isSaving: boolean;
  saveError: string;
  onCommit: () => void;
  onCancel: () => void;
};

export default function ReviewModal({ isOpen, isSaving, saveError, onCommit, onCancel }: Props) {
  if (!isOpen) {
    return null;
  }

  const buttons = [
    <Button
      key="commit"
      variant="primary"
      buttonColor="action"
      disabled={isSaving}
      onClick={onCommit}
    >
      <FormattedMessage id="8874a7a2-0e14-4413-8d0e-457de255f20b" defaultMessage="Save changes" />
    </Button>,
    <Button key="cancel" variant="tertiary" disabled={isSaving} onClick={onCancel}>
      <FormattedMessage id="67dfddc0-148e-4112-ae48-6d66d8b22412" defaultMessage="Cancel" />
    </Button>,
  ];

  return (
    <WorkflowModal
      closeBehavior={{ tag: "with-button", onClose: onCancel }}
      title={
        <FormattedMessage
          id="352d7566-009a-4117-9a7e-d7ee73801cdb"
          defaultMessage="Review changes"
        />
      }
      buttons={buttons}
    >
      {isSaving && <LoadingIndicator />}
      {saveError && <AlertMessage kind="danger">{saveError}</AlertMessage>}
      <ReviewDiffs />
    </WorkflowModal>
  );
}
