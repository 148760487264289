import { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";

import SROnly from "common/core/screen_reader";
import BinaryToggle from "common/form/inputs/binary_toggle";

import { type ProofReasonCode } from "./context";
import { useReasonCodeDiff } from "./util";
import Styles from "./proof_reason_codes.module.scss";

const EnabledColumn = {
  collapse: true,

  Header: (
    <span>
      <FormattedMessage id="38dc1ca3-02ab-40f0-9f19-35769c8b317c" defaultMessage="Enabled" />
    </span>
  ),

  render: (reasonCode: ProofReasonCode) => {
    return <EnabledColumnCell reasonCode={reasonCode} />;
  },
};
export default EnabledColumn;

function EnabledColumnCell({ reasonCode }: { reasonCode: ProofReasonCode }) {
  const { canEdit, edited, displayValue, setEnabled } = useReasonCodeDiff({
    reasonCode,
    fieldName: "enabled",
  });

  const handleToggleChange = useCallback(
    (newValue: boolean) => setEnabled(reasonCode.id, newValue),
    [reasonCode, setEnabled],
  );

  return (
    <div className={classnames(edited && Styles.editedCell)}>
      <BinaryToggle
        label={
          <SROnly>
            <FormattedMessage id="3b52535c-c4f7-4917-a2f9-44a0e60586ab" defaultMessage="Enabled" />
          </SROnly>
        }
        value={displayValue}
        onChange={handleToggleChange}
        disabled={!canEdit}
      />
    </div>
  );
}
