import { type ComponentProps, type ReactNode, useState } from "react";
import { FormattedMessage } from "react-intl";

import { SmsAuthGate } from "common/auth_gate/sms";
import { EmailAuthGate } from "common/auth_gate/email";
import { TotpAuthGate } from "common/auth_gate/totp";
import Button from "common/core/button";
import ActionButton from "common/core/action_button";
import PopoutMenu from "common/core/popout_menu";
import PopoutMenuItem from "common/core/popout_menu/item";
import { Heading, Paragraph } from "common/core/typography";
import AlertMessage from "common/core/alert_message";
import { pendoUpdateMetadata } from "util/pendo";

import { BackButtonOnlyFooter, LoginHeader } from "./common";
import ProofCard from "../card";
import MFAStyles from "./mfa.module.scss";

export type MfaAuthOption =
  | { type: "sms_test_mode" }
  | {
      type: "sms";
      data: { userId: string; last4: string };
    }
  | {
      type: "email";
      data: { userId: string; hint: string };
    }
  | {
      type: "time_based_one_time_password";
      data: { userId: string };
    };
export type MfaScreenType = {
  type: "mfa";
  authOptions: MfaAuthOption[];
  email: string;
  password: string;
};

function Title({ children }: { children: ReactNode }) {
  return (
    <Heading level="h3" textStyle="headingSix" className={MFAStyles.title}>
      {children}
    </Heading>
  );
}

function AuthButton(props: ComponentProps<typeof Button>) {
  return (
    <Button
      {...props}
      className={MFAStyles.actionButton}
      buttonColor="action"
      buttonSize="large"
      variant="primary"
      fullwidth
    />
  );
}

function SmsAuth({
  userId,
  last4,
  onSuccess,
  password,
  authOptions,
}: {
  userId: string;
  last4: string;
  onSuccess: () => void;
  password: string;
  authOptions: MfaAuthOption[];
}) {
  const smsTestMode = authOptions.some((option) => option.type === "sms_test_mode");
  return (
    <>
      <Title>
        <FormattedMessage
          id="1e1ba0c5-ab59-4677-a769-5c056edb24ff"
          defaultMessage="SMS Authentication"
        />
      </Title>
      <SmsAuthGate
        subjectId={userId}
        tooltip={
          <FormattedMessage
            id="4a674724-592a-49c1-92ee-396118dee40f"
            defaultMessage="You listed this as your mobile number in your profile settings. Please change authentication methods or contact support if this is not where you receive text messages."
          />
        }
        last4={last4}
        onSuccess={onSuccess}
        password={password}
      >
        {({ content, action }) => (
          <>
            <Paragraph className={MFAStyles.info}>{content}</Paragraph>
            {action.type === "initiate" && (
              <>
                <AuthButton {...action.buttonProps}>
                  <FormattedMessage
                    id="530ffe9f-6d22-438e-abcb-41b066ba3f85"
                    defaultMessage="Send verification text"
                  />
                </AuthButton>
                {smsTestMode && (
                  <AlertMessage className={MFAStyles.alertMessage} kind="info" centerText>
                    <div className={MFAStyles.testBoxContainer}>
                      <div className={MFAStyles.text}>
                        <FormattedMessage
                          id="b8a02db9-ca5b-4786-aa52-0dc82ccf227f"
                          defaultMessage="Skip using code. Visible in test environments only."
                        />
                      </div>
                      <Button
                        {...action.testModeButtonProps}
                        buttonColor="action"
                        variant="primary"
                      >
                        <FormattedMessage
                          id="dbd74569-3445-4926-85dc-e6defb23e69a"
                          defaultMessage="Use test code"
                        />
                      </Button>
                    </div>
                  </AlertMessage>
                )}
              </>
            )}
            {action.type === "submit" && (
              <AuthButton {...action.buttonProps}>
                <FormattedMessage
                  id="6634e55f-66cc-4f64-b9b3-7cf558b763c0"
                  defaultMessage="Verify"
                />
              </AuthButton>
            )}
          </>
        )}
      </SmsAuthGate>
    </>
  );
}

function EmailAuth({
  userId,
  hint,
  onSuccess,
  password,
}: {
  userId: string;
  hint: string;
  onSuccess: () => void;
  password: string;
}) {
  return (
    <>
      <Title>
        <FormattedMessage
          id="281ba03d-63ec-4c98-acda-204c329198c8"
          defaultMessage="Email Authentication"
        />
      </Title>
      <EmailAuthGate subjectId={userId} hint={hint} onSuccess={onSuccess} password={password}>
        {({ content, action }) => (
          <>
            <Paragraph className={MFAStyles.info}>{content}</Paragraph>
            {action.type === "initiate" && (
              <AuthButton {...action.buttonProps}>
                <FormattedMessage
                  id="b74a4a5b-f6c6-4bd4-a608-119d32a4ea75"
                  defaultMessage="Send verification email"
                />
              </AuthButton>
            )}
            {action.type === "submit" && (
              <AuthButton {...action.buttonProps}>
                <FormattedMessage
                  id="aa78045d-a184-4cff-b3a0-960727d52c44"
                  defaultMessage="Verify"
                />
              </AuthButton>
            )}
          </>
        )}
      </EmailAuthGate>
    </>
  );
}

function TotpAuth({
  userId,
  onSuccess,
  password,
}: {
  userId: string;
  onSuccess: () => void;
  password: string;
}) {
  return (
    <>
      <Title>
        <FormattedMessage
          id="b1153f5f-4421-470b-800d-7acb79667980"
          defaultMessage="Authenticator App"
        />
      </Title>
      <TotpAuthGate subjectId={userId} onSuccess={onSuccess} password={password}>
        {({ content, action }) => (
          <>
            <Paragraph className={MFAStyles.info}>{content}</Paragraph>
            <AuthButton {...action.buttonProps}>
              <FormattedMessage id="02137b7f-82a0-4568-a386-078ac6361a27" defaultMessage="Verify" />
            </AuthButton>
          </>
        )}
      </TotpAuthGate>
    </>
  );
}

function renderAuth(authData: AuthData) {
  const { authMethod, authOptions, onMfaSuccess, password } = authData;
  switch (authMethod.type) {
    case "sms":
      return (
        <SmsAuth
          userId={authMethod.data.userId}
          last4={authMethod.data.last4}
          onSuccess={onMfaSuccess}
          password={password}
          authOptions={authOptions}
        />
      );
    case "email":
      return (
        <EmailAuth
          userId={authMethod.data.userId}
          hint={authMethod.data.hint}
          onSuccess={onMfaSuccess}
          password={password}
        />
      );
    case "time_based_one_time_password":
      return (
        <TotpAuth userId={authMethod.data.userId} onSuccess={onMfaSuccess} password={password} />
      );
    default:
      throw new Error(`unknown auth method: ${JSON.stringify(authMethod)}`);
  }
}

const AUTH_METHOD_LABELS = Object.freeze({
  sms_test_mode: null,
  sms: <FormattedMessage id="30318f4a-f700-4d45-af8b-7183436973ab" defaultMessage="SMS" />,
  email: <FormattedMessage id="4acab34c-c58f-4c0c-a6e5-c50aca0cdd4b" defaultMessage="Email" />,
  time_based_one_time_password: (
    <FormattedMessage
      id="421bb80a-cc1f-4566-883c-1025c4c297dc"
      defaultMessage="Authenticator App"
    />
  ),
});

const LAST_USED_KEY = "last-used-auth-method";

type Props = {
  authOptions: MfaAuthOption[];
  onMfaSuccess: () => void;
  password: string;
  showCard?: boolean;
  wrapper?: (children: ReactNode) => ReactNode;
  onBack: () => void;
};
type AuthData = {
  authMethod: MfaAuthOption;
  authOptions: MfaAuthOption[];
  onMfaSuccess: () => void;
  password: string;
};

function MfaScreenElement({
  authOptions,
  onMfaSuccess,
  password,
}: {
  authOptions: MfaAuthOption[];
  onMfaSuccess: () => void;
  password: string;
}) {
  const dropdownAuthOptions = authOptions.filter((option) => option.type !== "sms_test_mode");
  const initialAuthMethod =
    dropdownAuthOptions.find((o) => o.type === localStorage.getItem(LAST_USED_KEY)) ||
    dropdownAuthOptions[0];
  const [authMethod, setAuthMethod] = useState<MfaAuthOption>(initialAuthMethod);

  const onSuccess = () => {
    if (authMethod.type !== "sms_test_mode") {
      pendoUpdateMetadata(null, authMethod.data.userId);
    }
    onMfaSuccess();
  };

  return (
    <>
      {renderAuth({ authMethod, authOptions, onMfaSuccess: onSuccess, password })}
      {dropdownAuthOptions.length > 1 && (
        <PopoutMenu
          className={MFAStyles.switcherButton}
          target={
            <ActionButton>
              <FormattedMessage
                id="d1d5bafd-a8ce-4e4f-9f0c-b4f4f25e7d8a"
                defaultMessage="Change authentication method"
              />
            </ActionButton>
          }
          placement="bottomLeft"
        >
          {({ close }) =>
            dropdownAuthOptions.map((option) => (
              <PopoutMenuItem
                key={option.type}
                onClick={() => {
                  close();
                  setAuthMethod(option);
                  localStorage.setItem(LAST_USED_KEY, option.type);
                }}
              >
                {AUTH_METHOD_LABELS[option.type]}
              </PopoutMenuItem>
            ))
          }
        </PopoutMenu>
      )}
    </>
  );
}

export default function MFAScreen({
  authOptions,
  onMfaSuccess,
  password,
  showCard,
  wrapper,
  onBack,
}: Props) {
  const content = (
    <MfaScreenElement authOptions={authOptions} onMfaSuccess={onMfaSuccess} password={password} />
  );

  if (showCard) {
    return (
      <ProofCard
        header={<LoginHeader />}
        body={
          <MfaScreenElement
            authOptions={authOptions}
            onMfaSuccess={onMfaSuccess}
            password={password}
          />
        }
        footer={<BackButtonOnlyFooter onClick={onBack} />}
      />
    );
  }

  return <>{wrapper ? wrapper(content) : content}</>;
}
