import { defineMessages, useIntl } from "react-intl";
import classnames from "classnames";

import { Heading, Paragraph } from "common/core/typography";
import { ButtonStyledLink } from "common/core/button/button_styled_link";
import { Select } from "common/core/form/select";
import Icon from "common/core/icon";

import { type OrganizationDomains_organization_Organization_samlProviders as SamlProvider } from "../organization_domains.query.graphql";
import Styles from "./index.module.scss"; // TODO: move to separate file?

const MESSAGES = defineMessages({
  ssoNoConfigsTitle: {
    id: "ed87ff23-d23e-4677-9335-423b57376b35",
    defaultMessage: "No IDP/SAML configurations yet",
  },
  ssoNoConfigsDescription: {
    id: "a919ccc7-6cb3-4dac-b74e-d551d3cf531b",
    defaultMessage:
      "To enable domain-based single sign-on (SSO) for this domain, you must set up an identity provider and SAML configuration, then return here to apply it.",
  },
  ssoNoConfigsConfigurePrompt: {
    id: "0fd3141d-d793-4cf1-996e-e73f4819210b",
    defaultMessage: "Configure identity provider",
  },
  ssoOptionsTitle: {
    id: "31a0934a-2590-493d-931c-03a217e852fd",
    defaultMessage: "IDP/SAML configuration",
  },
  ssoOptionsDescription: {
    id: "5bd53385-f616-4ede-aa83-b7988131e932",
    defaultMessage:
      "Select from an existing SSO configuration. To view or create a new one, go to your identity providers tab.",
  },
  ssoPoliciesTitle: {
    id: "185045ff-bd5f-493a-b23c-974c7c657984",
    defaultMessage: "Provisioning and routing policies",
  },
  ssoPoliciesDescription: {
    id: "5e065872-8371-4c51-a5fe-725df5c14539",
    defaultMessage:
      "With domain-based SSO, these are your provisioning and routing settings. They cannot be edited.",
  },
  ssoPoliciesJIT: {
    id: "1aab0461-9ccb-4fa9-9bd4-400d63069d60",
    defaultMessage: "Just-in-time (JIT) provisioning",
  },
  ssoPoliciesRUA: {
    id: "ee21c52f-fc0f-424b-8f13-7bca8b4f53c6",
    defaultMessage: "Unmanaged account creation blocked",
  },
  ssoPoliciesRouting: {
    id: "bd244e7d-8231-495e-9393-4a8e987314ce",
    defaultMessage:
      "Routes users to your Root Organization as Team Member if not configured in your IDP.",
  },
  ssoPoliciesExcludedUsersTitle: {
    id: "65c2709d-6442-4b01-a454-c3e8556b46ee",
    defaultMessage: "Excluded Users",
  },
  ssoPoliciesExcludedUsersDescription: {
    id: "4519c573-8a9d-4245-bb07-b9dc6d53f448",
    defaultMessage:
      "Admins and Owners at your root organization ({orgGid}) with access to Command Center are excluded from the SSO requirement. They can log in with email and password. We recommend that you turn multi-factor authentication (MFA) on at your root organization for added security for these users.",
  },
});

export type SsoOptionsProps = {
  samlProviderId: string | null;
  setSamlProviderId: (id: string | null) => void;
  samlProviders: SamlProvider[];
  activeOrganizationId: string;
  isEditing: boolean;
};

export function SsoOptions({
  samlProviders,
  samlProviderId,
  setSamlProviderId,
  isEditing,
  activeOrganizationId,
}: SsoOptionsProps) {
  const intl = useIntl();
  if (samlProviders.length === 0) {
    return <SsoOptionsNoExistingConfigs />;
  }

  const samlProviderItems = samlProviders.map((sp) => {
    return { value: sp.id, label: sp.name || `Unnamed IDP - ${sp.id}` };
  });

  return (
    <div className={classnames(Styles.fadeIn, Styles.ssoOptionsContainer)}>
      <div>
        <Heading level="h5" textStyle="headingSix">
          {intl.formatMessage(MESSAGES.ssoOptionsTitle)}
        </Heading>
        <Paragraph textColor="subtle">
          {intl.formatMessage(MESSAGES.ssoOptionsDescription)}
        </Paragraph>
        <Select
          items={samlProviderItems}
          value={samlProviderId || undefined}
          onChange={({ target }) => setSamlProviderId(target.value)}
          aria-invalid={false}
          disabled={!isEditing}
          data-automation-id="idp-select"
        />
      </div>
      <div>
        <Heading level="h6" textStyle="headingSix">
          {intl.formatMessage(MESSAGES.ssoPoliciesTitle)}
        </Heading>
        <Paragraph textColor="subtle">
          {intl.formatMessage(MESSAGES.ssoPoliciesDescription)}
        </Paragraph>
        <Paragraph size="large">
          <Icon name="success" className={Styles.icon} />
          {intl.formatMessage(MESSAGES.ssoPoliciesJIT)}
        </Paragraph>
        <Paragraph size="large">
          <Icon name="success" className={Styles.icon} />
          {intl.formatMessage(MESSAGES.ssoPoliciesRUA)}
        </Paragraph>
        <Paragraph size="large">
          <Icon name="success" className={Styles.icon} />
          {intl.formatMessage(MESSAGES.ssoPoliciesRouting)}
        </Paragraph>
      </div>

      <div>
        <Heading level="h6" textStyle="headingSix">
          {intl.formatMessage(MESSAGES.ssoPoliciesExcludedUsersTitle)}
        </Heading>
        <Paragraph>
          {intl.formatMessage(MESSAGES.ssoPoliciesExcludedUsersDescription, {
            orgGid: activeOrganizationId,
          })}
        </Paragraph>
      </div>
    </div>
  );
}

function SsoOptionsNoExistingConfigs() {
  const intl = useIntl();
  return (
    <div className={classnames(Styles.fadeIn, Styles.ssoOptionsContainerNoConfigs)}>
      <Heading level="h5" textStyle="headingSix">
        {intl.formatMessage(MESSAGES.ssoNoConfigsTitle)}
      </Heading>
      <Paragraph textAlign="center">
        {intl.formatMessage(MESSAGES.ssoNoConfigsDescription)}
      </Paragraph>
      <ButtonStyledLink variant="tertiary" to="/command-center/access/identity-providers/new">
        {intl.formatMessage(MESSAGES.ssoNoConfigsConfigurePrompt)}
      </ButtonStyledLink>
    </div>
  );
}
