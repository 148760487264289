import { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";

import AlertMessage from "common/core/alert_message";
import Button from "common/core/button";
import { type ProofReasonCodeInput, type UpdateProofReasonCodesInput } from "graphql_globals";
import { captureException } from "util/exception";
import { useMutation } from "util/graphql";

import { useProofReasonCodes, type ReasonCodeEdits } from "./context";
import DiscardConfirmModal from "./discard_confirm_modal";
import ReviewModal from "./review_modal";
import ProofReasonCodeBulkMutation from "./proof_reason_codes_bulk.mutation.graphql";
import Styles from "./proof_reason_codes.module.scss";

function makeProofReasonCodeInput(edits: ReasonCodeEdits): UpdateProofReasonCodesInput {
  const reasonCodes: ProofReasonCodeInput[] = Object.entries(edits).map(
    ([reasonCodeId, reasonCodeEdit]) => ({
      id: reasonCodeId,
      ...reasonCodeEdit,
    }),
  );
  return { reasonCodes };
}

type SaveState =
  | {
      status: "idle" | "saving" | "confirming" | "success";
    }
  | {
      status: "error";
      message: string;
    };

export default function ActionButtons() {
  const { edits, discardAllEdits } = useProofReasonCodes();
  const hasEdits = Boolean(Object.keys(edits).length);

  const [discardConfirmOpen, setDiscardConfirmOpen] = useState(false);
  const [reviewOpen, setReviewOpen] = useState(false);
  const [saveState, setSaveState] = useState<SaveState>({ status: "idle" });

  const bulkEditReasonCodes = useMutation(ProofReasonCodeBulkMutation);

  const handleDiscard = useCallback(() => {
    setDiscardConfirmOpen(true);
  }, []);

  const handleConfirmDiscard = useCallback(() => {
    setDiscardConfirmOpen(false);
    discardAllEdits();
  }, []);

  const handleCancelDiscard = useCallback(() => {
    setDiscardConfirmOpen(false);
  }, []);

  const handleReview = useCallback(() => {
    setSaveState({ status: "idle" });
    setReviewOpen(true);
  }, []);

  const handleReviewCancel = useCallback(() => {
    setReviewOpen(false);
  }, []);

  const handleReviewCommit = useCallback(async () => {
    try {
      setSaveState({ status: "saving" });
      await bulkEditReasonCodes({
        variables: {
          input: makeProofReasonCodeInput(edits),
        },
      });
      setReviewOpen(false);
      setSaveState({ status: "success" });
      window.setTimeout(() => setSaveState({ status: "idle" }), 5000);
    } catch (e) {
      captureException(e);
      setSaveState({ status: "error", message: (e as Error).message });
    }
  }, [edits]);

  return (
    <>
      <DiscardConfirmModal
        isOpen={discardConfirmOpen}
        onConfirm={handleConfirmDiscard}
        onCancel={handleCancelDiscard}
      />

      <ReviewModal
        isOpen={reviewOpen}
        isSaving={saveState.status === "saving"}
        saveError={saveState.status === "error" ? saveState.message : ""}
        onCommit={handleReviewCommit}
        onCancel={handleReviewCancel}
      />

      <div className={Styles.buttonBar}>
        {saveState.status === "success" && (
          <AlertMessage kind="success">
            <FormattedMessage
              id="b606f16c-79eb-46d4-a8dc-1c3cef03e12e"
              defaultMessage="Changes saved"
            />
          </AlertMessage>
        )}
        <Button
          variant="secondary"
          buttonColor="danger"
          onClick={handleDiscard}
          disabled={!hasEdits}
        >
          <FormattedMessage
            id="2155b0c4-353c-45c7-8d3a-e2c243de73b6"
            defaultMessage="Discard changes"
          />
        </Button>
        <Button variant="primary" buttonColor="action" onClick={handleReview} disabled={!hasEdits}>
          <FormattedMessage
            id="456cb25f-cfec-47d0-930b-2eb08c427d9b"
            defaultMessage="Save changes"
          />
        </Button>
      </div>
    </>
  );
}
