import { useMemo, memo, type ReactNode } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";

import { NOTARY_TIER_LABELS } from "constants/notary";
import {
  NotaryCapacityType,
  NotaryPresenceStatuses,
  NotaryOnboardingStatuses,
  NotaryComplianceStatuses,
  NotaryAccountStatuses,
  NotaryLevels,
  NotaryActivationStatus,
} from "graphql_globals";
import { useLanguageOptions } from "common/language";

import type { NotaryList_viewer as Viewer } from "./notary_list_query.graphql";

type Capacity = {
  type: NotaryCapacityType;
  status: NotaryActivationStatus;
};

export const MESSAGES = defineMessages({
  searchTermPlaceholder: {
    id: "80b9969f-5bea-416f-becf-f67dea261a53",
    defaultMessage: "Search Email, Name or GID",
  },
  commissionNumberPlaceholder: {
    id: "25d6a49f-ef0d-4044-9e8f-20e8db76bc8f",
    defaultMessage: "Commission Number",
  },
  organizationPlacholder: {
    id: "ec0cb0f8-4800-4d55-8592-ca4c4e8145c0",
    defaultMessage: "Organization",
  },
  onboardingPlacholder: {
    id: "a7ae13ac-312e-4648-8dcc-60875662ac1f",
    defaultMessage: "Onboarding",
  },
  accountFilterPlacholder: {
    id: "8c6c8649-3216-404a-ac31-03c588b5b839",
    defaultMessage: "Account Status",
  },
  accountHeaderPlacholder: {
    id: "458e4165-941a-4ee6-bbc7-54e41227aae4",
    defaultMessage: "Account",
  },
  trainingPlaceholder: {
    id: "eb895b16-ab75-4731-b525-6efc25eed0a0",
    defaultMessage: "Training",
  },
  compliancePlacholder: {
    id: "84b78889-6632-401c-8888-6b3c06650522",
    defaultMessage: "Compliance",
  },
  lastUpdatedPlacholder: {
    id: "3a952453-7c11-45f7-859a-c50b6a4443f2",
    defaultMessage: "Last Updated",
  },
  tierPlacholder: {
    id: "4d95ecc8-d09f-4ee3-aced-83c2a960cbf7",
    defaultMessage: "Tier",
  },
  statePlacholder: {
    id: "f97e0abe-b68a-4248-9ded-8bd0c44e87d5",
    defaultMessage: "US State",
  },
  availabilityPlacholder: {
    id: "f545422f-d033-488a-97fb-6c72a1b5cb15",
    defaultMessage: "Availability",
  },
  languagePlaceholder: {
    id: "33af7121-4116-4fd4-9bcd-758c1d0cc073",
    defaultMessage: "Language",
  },
});

export const ONBOARDING_STATUSES = Object.freeze({
  [NotaryOnboardingStatuses.INVITED]: (
    <FormattedMessage id="b978c625-2428-430b-8a4f-adee58df77e4" defaultMessage="Invited" />
  ),
  [NotaryOnboardingStatuses.INITIATED]: (
    <FormattedMessage id="8346161f-f10c-423b-bd99-0d48256a1656" defaultMessage="Initiated" />
  ),
  [NotaryOnboardingStatuses.COMPLETED]: (
    <FormattedMessage id="1713eee9-c912-4e92-9ad7-4fb0c3b6d45c" defaultMessage="Completed" />
  ),
  [NotaryOnboardingStatuses.PAUSED]: (
    <FormattedMessage id="05100388-020f-425b-a7e0-4110ee3fed6a" defaultMessage="Paused" />
  ),
});

const NOTARY_LEVELS = Object.freeze({
  [NotaryLevels.PARTNER]: (
    <FormattedMessage id="bf477f7f-dac5-4e73-b010-c548fd85adbc" defaultMessage="Partner (retail)" />
  ),
  [NotaryLevels.SILVER]: (
    <FormattedMessage
      id="e1d46415-8270-4b80-859a-b8f0ed27837c"
      defaultMessage="Silver (retail + business)"
    />
  ),
  [NotaryLevels.GOLD]: (
    <FormattedMessage
      id="bc6eeeb6-adde-4338-8119-add051bc1ca4"
      defaultMessage="Gold (retail + business + real estate)"
    />
  ),
  [NotaryLevels.PLATINUM]: (
    <FormattedMessage
      id="43d6db75-4df2-4d23-9c69-f4c6e444f160"
      defaultMessage="Platinum (retail + business + real estate + byot)"
    />
  ),
});

export const ACCOUNT_STATUSES = Object.freeze({
  [NotaryAccountStatuses.ENABLED]: (
    <FormattedMessage id="da4ce78c-1820-45b3-8c7d-dcdb113bda6f" defaultMessage="Enabled" />
  ),
  [NotaryAccountStatuses.DISABLED]: (
    <FormattedMessage id="80971f24-e4e8-4ab6-b25a-10fcf9406802" defaultMessage="Disabled" />
  ),
});

export const COMPLIANCE_STATUSES = Object.freeze({
  [NotaryComplianceStatuses.COMPLIANT]: (
    <FormattedMessage id="d6910b85-622c-492d-8f59-1165994ab21c" defaultMessage="Compliant" />
  ),
  [NotaryComplianceStatuses.NOT_COMPLIANT]: (
    <FormattedMessage id="f66118f0-5ea2-4935-86f4-737bb48c1c34" defaultMessage="Non-compliant" />
  ),
  [NotaryComplianceStatuses.NEEDS_REVIEW]: (
    <FormattedMessage id="85a35db2-a33d-4cd0-9b0b-48088e163485" defaultMessage="Needs Review" />
  ),
});

export const PRESENCE_STATUSES = Object.freeze({
  [NotaryPresenceStatuses.UNAVAILABLE]: (
    <FormattedMessage id="58ec9d92-de57-4a48-ad9f-72a3b60c00db" defaultMessage="In meeting" />
  ),
  [NotaryPresenceStatuses.AVAILABLE]: (
    <FormattedMessage id="25e7abee-47fc-4c1d-b145-fdd79f9f8b00" defaultMessage="Available" />
  ),
  [NotaryPresenceStatuses.OFFLINE]: (
    <FormattedMessage id="045b8b53-0bfe-43a5-937e-20530a268c30" defaultMessage="Offline" />
  ),
});

export const CAPACITY_STATUS_LABELS = Object.freeze({
  [NotaryActivationStatus.ACCEPTED]: (
    <FormattedMessage id="73378327-6118-45b4-a6df-e39de7236fca" defaultMessage="Approved" />
  ),
  [NotaryActivationStatus.REJECTED]: (
    <FormattedMessage id="497fe28a-28f2-4316-921e-3c62a8477176" defaultMessage="Disabled" />
  ),
  [NotaryActivationStatus.PENDING]: (
    <FormattedMessage id="2a5c80a3-3fcf-427e-875c-7ef4ddfe2870" defaultMessage="On Hold" />
  ),
  [NotaryActivationStatus.TERMINATED]: (
    <FormattedMessage id="3e2df8ce-f3ec-4cf7-988f-ff4a19c430f8" defaultMessage="Terminated" />
  ),
  [NotaryActivationStatus.INVITED]: (
    <FormattedMessage id="f496ded4-d977-4c91-8f1b-64af97104843" defaultMessage="Invited" />
  ),
});

export const CAPACITY_CONDENSED_STATUS_LABELS = Object.freeze({
  active: <FormattedMessage id="4e2964fe-fa80-4990-a3d0-d818ef53f8dd" defaultMessage="Active" />,
  inactive: (
    <FormattedMessage id="e79e3871-83e8-4525-b763-00360186f34a" defaultMessage="Inactive" />
  ),
});

export function isCondensedStatusActive(status: NotaryActivationStatus): boolean {
  return status === NotaryActivationStatus.ACCEPTED;
}

export function condensedStatusLabel(status: NotaryActivationStatus): ReactNode {
  return isCondensedStatusActive(status)
    ? CAPACITY_CONDENSED_STATUS_LABELS.active
    : CAPACITY_CONDENSED_STATUS_LABELS.inactive;
}

function capacityStatusLabel(capacity: Capacity): ReactNode {
  return capacity.type === NotaryCapacityType.ON_DEMAND
    ? CAPACITY_STATUS_LABELS[capacity.status]
    : condensedStatusLabel(capacity.status);
}

export const TRAINING_STATUSES = Object.freeze({
  true: <FormattedMessage id="4ade08aa-0046-4a64-aa99-4a1782049104" defaultMessage="Enabled" />,
  false: <FormattedMessage id="d07d9f6f-6d4b-452e-be4d-47bba15b6eb2" defaultMessage="Disabled" />,
});

export const CAPACITY_TYPE_LABELS = Object.freeze({
  [NotaryCapacityType.BRING_YOUR_OWN_TRANSACTIONS]: (
    <FormattedMessage id="f1fc2cef-06e9-4ba0-ad42-aaa5ecfeff39" defaultMessage="NST" />
  ),
  [NotaryCapacityType.ON_DEMAND]: (
    <FormattedMessage id="87a0e452-3d63-4b41-b765-6877e5f0e350" defaultMessage="ODN" />
  ),
  [NotaryCapacityType.ORGANIZATION_NOTARY]: (
    <FormattedMessage id="21e65778-58f2-4686-9d79-7c948373248d" defaultMessage="IHN" />
  ),
});

export function useConfiguration(notaryUsStates?: Viewer["notaryUsStates"]) {
  const intl = useIntl();
  const headings = [
    <FormattedMessage key="name" id="430fb664-63d3-402b-897e-2d7fc845bef3" defaultMessage="Name" />,
    <FormattedMessage
      key="caps"
      id="1d136dea-3d57-4917-9b21-d31d42c99dbf"
      defaultMessage="Capacities"
    />,
    intl.formatMessage(MESSAGES.organizationPlacholder),
    intl.formatMessage(MESSAGES.tierPlacholder),
    intl.formatMessage(MESSAGES.statePlacholder),
    intl.formatMessage(MESSAGES.onboardingPlacholder),
    intl.formatMessage(MESSAGES.compliancePlacholder),
    intl.formatMessage(MESSAGES.accountHeaderPlacholder),
    intl.formatMessage(MESSAGES.availabilityPlacholder),
    intl.formatMessage(MESSAGES.trainingPlaceholder),
    intl.formatMessage(MESSAGES.lastUpdatedPlacholder),
  ];
  const tierDropdownItems = useMemo(
    () =>
      Object.freeze(
        Array.from(Array(4), (_, i) => ({
          label: (
            <FormattedMessage
              id="940a9529-a736-44d9-a765-cf57fc7674fa"
              defaultMessage="Tier {value}"
              values={{ value: i + 1 }}
            />
          ),
          value: i + 1,
        })),
      ),
    [],
  );
  const stateDropdownItems = useMemo(
    () =>
      notaryUsStates
        ? notaryUsStates.map((state) => {
            return { value: state!.id, label: state!.name };
          })
        : [],
    [notaryUsStates],
  );
  const presenceStatusDropdownItems = useMemo(
    () =>
      Object.freeze(
        Object.entries(PRESENCE_STATUSES).map(([value, label]) => ({
          value: value as NotaryPresenceStatuses,
          label,
        })),
      ),
    [],
  );
  const onboardingStatusDropdownItems = useMemo(
    () =>
      Object.freeze(
        Object.entries(ONBOARDING_STATUSES).map(([value, label]) => ({
          value: value as NotaryOnboardingStatuses,
          label,
        })),
      ),
    [],
  );
  const levelsDropdownItems = useMemo(
    () =>
      Object.freeze(
        Object.entries(NOTARY_LEVELS).map(([value, label]) => ({
          value: value as NotaryLevels,
          label,
        })),
      ),
    [],
  );
  const tiersExpandedDropdownItems = useMemo(
    () =>
      Object.freeze(
        NOTARY_TIER_LABELS.map(({ value, label }) => ({
          label: intl.formatMessage(label),
          value: value.toString(),
        })),
      ),
    [intl],
  );
  const complianceStatusDropdownItems = useMemo(
    () =>
      Object.freeze(
        Object.entries(COMPLIANCE_STATUSES).map(([value, label]) => ({
          value: value as NotaryComplianceStatuses,
          label,
        })),
      ),
    [],
  );
  const accountStatusDropdownItems = useMemo(
    () =>
      Object.freeze(
        Object.entries(ACCOUNT_STATUSES).map(([value, label]) => ({
          value: value as NotaryAccountStatuses,
          label,
        })),
      ),
    [],
  );
  const trainingDropdownItems = useMemo(
    () =>
      Object.freeze(
        Object.entries(TRAINING_STATUSES).map(([value, label]) => ({
          value: value === "true",
          label,
        })),
      ),
    [],
  );

  return {
    tierDropdownItems,
    tiersExpandedDropdownItems,
    languagesDropdownItems: useLanguageOptions(),
    levelsDropdownItems,
    stateDropdownItems,
    headings,
    presenceStatusDropdownItems,
    onboardingStatusDropdownItems,
    complianceStatusDropdownItems,
    accountStatusDropdownItems,
    trainingDropdownItems,
  };
}

function NotaryCapacitiesSummaryInner({ capacities }: { capacities: Capacity[] }) {
  return capacities.length ? (
    <>
      {capacities.map((cap) => (
        <div key={cap.type}>
          {capacityStatusLabel(cap)} {CAPACITY_TYPE_LABELS[cap.type]}
        </div>
      ))}
    </>
  ) : (
    <div className="NotaryList--Capacities--none">
      <FormattedMessage id="151907bf-be26-446d-a552-d68058313e86" defaultMessage="No capacities" />
    </div>
  );
}

export const NotaryCapacitiesSummary = memo(NotaryCapacitiesSummaryInner);
