import { defineMessages, useIntl } from "react-intl";
import { useEffect, useState } from "react";

import { SettingGroupName } from "graphql_globals";
import { pushNotification } from "common/core/notification_center/actions";
import { useMutation, useQuery } from "util/graphql";
import Button from "common/core/button";
import { Card, CardSection } from "common/core/card";
import { RadioInput, RadioGroup, RadioLabel } from "common/core/form/option";
import { useForm } from "common/core/form";
import { NOTIFICATION_SUBTYPES, NOTIFICATION_TYPES } from "constants/notifications";

import DefaultNetsuiteSettingQuery, {
  type DefaultNetsuiteSettings_organization_Organization as Organization,
} from "./default_netsuite_setting_query.graphql";
import UpdateDefaultNetsuiteTermSettingMutation from "./update_default_netsuite_term_setting_mutation.graphql";

const NETSUITE_TERM_SETTING = "NETSUITE_INVOICE_TERM";

type Props = {
  organizationId: string;
};
type FormValues = {
  termSetting: string;
};

enum NetsuiteTerm {
  NET_30 = "30",
  NET_45 = "45",
}

const MESSAGES = defineMessages({
  netsuiteTermTitle: {
    id: "5d95fb01-0950-4172-bbb9-a6212c0b297c",
    defaultMessage: "Batched Netsuite Invoice Terms",
  },
  netsuite30: {
    id: "dd41f600-2f24-41c7-9bf5-d1200ec8d052",
    defaultMessage: "30 days",
  },
  netsuite45: {
    id: "fc8d92fa-b0c0-41fb-9c8c-f07b9af02864",
    defaultMessage: "45 days",
  },
  save: {
    id: "442e9157-9a8f-49b8-b655-6b40b54b4a16",
    defaultMessage: "Save",
  },
  settingSaveSuccess: {
    id: "522afc5c-07ad-47b7-8abf-f63f707a3b43",
    defaultMessage: "Netsuite term setting updated",
  },
  settingSaveError: {
    id: "6979d71d-f042-417e-892d-08fa5aeaa940",
    defaultMessage: "Netsuite term setting failed to update",
  },
});

export function NetsuiteTermsForm({ organizationId }: Props) {
  const intl = useIntl();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const form = useForm<FormValues>({
    defaultValues: {
      termSetting: NetsuiteTerm.NET_30,
    },
  });

  const updateOrganizationNetsuiteTermSetting = useMutation(
    UpdateDefaultNetsuiteTermSettingMutation,
  );
  const handleSubmit = form.handleSubmit((data: FormValues) => {
    setIsSubmitting(true);

    return updateOrganizationNetsuiteTermSetting({
      variables: {
        input: {
          name: NETSUITE_TERM_SETTING,
          groupName: SettingGroupName.ORGANIZATION,
          groupId: organizationId,
          value: JSON.stringify(data.termSetting),
          reason: "Admin changed output",
        },
      },
    })
      .then(() => {
        pushNotification({
          message: intl.formatMessage(MESSAGES.settingSaveSuccess),
        });
      })
      .catch(() => {
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          message: intl.formatMessage(MESSAGES.settingSaveError),
          subtype: NOTIFICATION_SUBTYPES.ERROR,
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  });

  const { data, loading } = useQuery(DefaultNetsuiteSettingQuery, {
    variables: { organizationId },
  });

  const organization = data?.organization as Organization | undefined;
  const settings = organization?.settings;

  useEffect(() => {
    if (settings) {
      const netsuiteSetting = settings.find((setting) => setting.name === NETSUITE_TERM_SETTING);
      if (netsuiteSetting?.value) {
        form.setValue("termSetting", netsuiteSetting.value);
      }
    }
  }, [Boolean(settings)]);

  if (loading) {
    return null;
  }

  return (
    <Card
      footer={
        <Button
          automationId="save-default-netsuite-terms"
          type="submit"
          isLoading={isSubmitting}
          disabled={loading}
          buttonColor="action"
          variant="primary"
          onClick={handleSubmit}
        >
          {intl.formatMessage(MESSAGES.save)}
        </Button>
      }
    >
      <CardSection>
        <RadioGroup label={intl.formatMessage(MESSAGES.netsuiteTermTitle)}>
          <RadioLabel
            label={intl.formatMessage(MESSAGES.netsuite30)}
            radio={<RadioInput value={NetsuiteTerm.NET_30} {...form.register("termSetting")} />}
          />
          <RadioLabel
            label={intl.formatMessage(MESSAGES.netsuite45)}
            radio={<RadioInput value={NetsuiteTerm.NET_45} {...form.register("termSetting")} />}
          />
        </RadioGroup>
      </CardSection>
    </Card>
  );
}
