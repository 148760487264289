import { useState, type ReactNode } from "react";
import { useIntl, defineMessages } from "react-intl";
import classNames from "classnames";

import { HookFormPassword } from "common/account/password/password";
import { StyledTextInput } from "common/core/form/text";
import { useForm } from "common/core/form";
import { defaultRequiredMessage } from "common/core/form/error";
import { useA11y } from "common/accessibility";
import { useSelector } from "redux/util";
import TosMessage from "common/account/login/tos_message";
import Button from "common/core/button";
import ContentDivider from "common/core/content_divider";
import type { AuthCode } from "common/account/google_signin_button";

import ProofCard from "../card";
import {
  BackButton,
  LoginHeader,
  Continue,
  FooterButton,
  SignInWithGoogle,
  useIsGoogleSignInEnabled,
} from "./common";
import Styles from "./index.module.scss";
import type { MfaScreenType, MfaAuthOption } from "./mfa";
import type { ForgotPasswordScreenType } from "./forgot_password";
import type { EmailScreenType, OnAuthenticationFailed } from "./email";

export type FormValues = {
  password: string;
};

const MESSAGES = defineMessages({
  emailLabel: {
    id: "b9f407a9-5708-492d-9f8a-763b4d246e3e",
    defaultMessage: "Email address",
  },
  passwordLabel: {
    id: "9b84ddd1-0053-46b9-ba31-b94272047ad3",
    defaultMessage: "Password",
  },
  backButtonAriaLabel: {
    id: "db04da04-83e0-4182-8a77-3977a3a98f53",
    defaultMessage: "Back to email input",
  },
  invalidError: {
    id: "0bb4c6ab-ec27-4cfd-b981-fe1ebf12791c",
    defaultMessage: "Email or password is invalid",
  },
  forgotPasswordLabel: {
    id: "01ae0cd6-4583-4fad-869a-bcc48215cb53",
    defaultMessage: "Forgot password?",
  },
});

export type PasswordScreenType = {
  type: "password";
  email: string;
  password?: string;
  needsAccount?: boolean;
};

export type MfaData = {
  auth_options: MfaAuthOption[];
};

type Props = {
  password?: string;
  email: string;
  withEmailReminder?: boolean;
  onNextScreen(screen: MfaScreenType | EmailScreenType | ForgotPasswordScreenType): void;
  onPasswordLogin: (values: FormValues & { email: string }) => Promise<MfaData | undefined>;
  onBack: () => void;
  onForgotPassword?: () => void;
  showCard?: boolean;
  wrapper?: (children: ReactNode) => ReactNode;
  hideTos?: boolean;
  onGoogleSignIn?: (authCode: AuthCode) => void;
  onAuthenticationFailed?: OnAuthenticationFailed;
};

function PasswordForm({
  onPasswordLogin,
  password,
  withEmailReminder,
  email,
  onNextScreen,
  onForgotPassword,
  hideTos,
  onGoogleSignIn,
  onAuthenticationFailed,
}: Omit<Props, "onBack">) {
  const intl = useIntl();
  const [loginLocked, setLoginLocked] = useState(false);
  const form = useForm<FormValues>({ mode: "all", defaultValues: { password: password || "" } });
  const { handleSubmit, register, formState, setFocus, setError } = form;
  const { errors, isSubmitted } = formState;
  const errorId = useA11y().useRegisteredId("password");
  const authentication = useSelector((state) => state.authentication);
  const isGoogleSignInEnabled = useIsGoogleSignInEnabled();

  const submitHandler = async (values: FormValues) => {
    if (loginLocked) {
      return;
    }
    setLoginLocked(true);
    try {
      const mfaData = await onPasswordLogin({ email, password: values.password });
      if (mfaData) {
        onNextScreen({
          type: "mfa",
          authOptions: mfaData.auth_options,
          email,
          password: values.password,
        });
      }
    } catch (error) {
      // We expect a success to redirect us. We don't need or want to unlock.
      setLoginLocked(false);
      setFocus("password");
      setError("password", {
        type: authentication.error.type,
        message: intl.formatMessage(MESSAGES.invalidError),
      });
      onAuthenticationFailed?.({
        withEmail: !!email,
        withPassword: !!password,
        withGoogle: false,
        error,
      });
    }
  };

  return (
    <form
      onSubmit={handleSubmit(submitHandler)}
      noValidate
      data-automation-id="authentication-form"
    >
      {withEmailReminder && (
        <StyledTextInput
          disabled
          aria-invalid="false"
          defaultValue={email}
          label={intl.formatMessage(MESSAGES.emailLabel)}
          data-automation-id="email-field"
        />
      )}
      <HookFormPassword
        setFocus={setFocus}
        label={intl.formatMessage(MESSAGES.passwordLabel)}
        registerProps={register("password", { required: defaultRequiredMessage(intl) })}
        invalid={Boolean(errors.password && isSubmitted)}
        error={isSubmitted ? errors.password : undefined}
        aria-describedby={authentication.error.error ? errorId : undefined}
        wrapperClass={withEmailReminder ? Styles.spacingTopSmall : undefined}
      />
      <Continue disabled={loginLocked} />
      {!hideTos && <TosMessage size="small" />}

      {onGoogleSignIn && (
        <div>
          {isGoogleSignInEnabled && (
            <>
              <ContentDivider />
              <SignInWithGoogle onGoogleSignIn={onGoogleSignIn} />
            </>
          )}
        </div>
      )}

      {onForgotPassword && (
        <Button
          variant="tertiary"
          buttonColor="action"
          onClick={onForgotPassword}
          fullwidth
          buttonSize="large"
          className={Styles.spacingTop}
        >
          {intl.formatMessage(MESSAGES.forgotPasswordLabel)}
        </Button>
      )}
    </form>
  );
}

function PasswordScreenCard({
  onPasswordLogin,
  password,
  withEmailReminder,
  email,
  onNextScreen,
  onBack,
  onForgotPassword,
  onAuthenticationFailed,
}: Props) {
  const intl = useIntl();

  return (
    <ProofCard
      header={<LoginHeader />}
      body={
        <PasswordForm
          {...{
            onPasswordLogin,
            password,
            withEmailReminder,
            email,
            onNextScreen,
            onAuthenticationFailed,
          }}
        />
      }
      footer={
        <div className={classNames(Styles.footerCentered)}>
          <BackButton
            onClick={onBack}
            aria-label={intl.formatMessage(MESSAGES.backButtonAriaLabel)}
          />
          <FooterButton variant="tertiary" buttonColor="action" onClick={onForgotPassword}>
            {intl.formatMessage(MESSAGES.forgotPasswordLabel)}
          </FooterButton>
        </div>
      }
    />
  );
}

export default function PasswordScreen(props: Props) {
  const { showCard, wrapper } = props;
  if (showCard) {
    return <PasswordScreenCard {...props} />;
  }
  return <>{wrapper ? wrapper(<PasswordForm {...props} />) : <PasswordForm {...props} />}</>;
}
