import { FormattedMessage } from "react-intl";

import { NotaryActivationStatus } from "graphql_globals";

export function FormattedActivationStatus({ status }: { status: NotaryActivationStatus }) {
  switch (status) {
    case NotaryActivationStatus.ACCEPTED:
      return <FormattedMessage id="d6220603-0d83-4bd7-ba04-4301557ef933" defaultMessage="Member" />;
    case NotaryActivationStatus.PENDING:
    case NotaryActivationStatus.INVITED:
      return (
        <FormattedMessage id="19e3684b-f9a9-4979-b74c-0844390857d3" defaultMessage="Invited" />
      );
    case NotaryActivationStatus.REJECTED:
    case NotaryActivationStatus.TERMINATED:
      return (
        <FormattedMessage id="73929286-d138-445b-925d-72458a1aa356" defaultMessage="Terminated" />
      );
  }
}
